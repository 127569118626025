import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import axios from "axios";

const ProgramDuplication = forwardRef((props, ref) => {
  const [token] = useState(localStorage.getItem("agentToken"));
  const [farmers, setFarmers] = useState([]);
  const [farmerOptions, setFarmerOptions] = useState([]);
  const [farmerSelect, setFarmerSelect] = useState([]);
  const [cropSelect, setCropSelect] = useState(
    props.crops.filter((crop)=>crop.id === props.cropId).map((crop)=>crop.name)[0]
  );
  const [farmerID, setFarmerID] = useState(
    props?.oldPlantingDetail?.farmerRel?.id
  );
  const [farmSize, setFarmSize] = useState(
    props?.oldPlantingDetail?.farmSize == null
      ? 0
      : props?.oldPlantingDetail?.farmSize
  );
  const [rowWidth, setRowWidth] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [, setShowSuccessMessage] = useState(false);
  const [farmSizeErrorMsg, setFarmSizeErrorMsg] = useState(false);
  const [rowWidthErrMsg, setRowWidthErrMsg] = useState(false);
  const [enableInputs, setEnableInputs] = useState(false);
  const [validCrop, setValidCrop] = useState(cropSelect != null);
  const [, setFormData] = useState({});

  useEffect(() => {
    if (farmers == null || farmers.length === 0) return;
    if (props.oldPlantingDetail == null) return;

    if (farmers != null && farmers.length > 0)
      handleFarmerChange(props.oldPlantingDetail.farmerRel.businessName);
  }, [farmers]);

  useImperativeHandle(ref, () => ({
    childFunction1() {
      formik.submitForm();
    },
  }));

  const formik = useFormik({
    initialValues: {
      programName: "",
      cultivar: props?.oldPlantingDetail?.cultivar,
      crop:
        props?.oldPlantingDetail?.crop != null
          ? props.oldPlantingDetail.crop
          : cropSelect,
      circles: props?.oldPlantingDetail?.circles,
      farmSize: props?.oldPlantingDetail?.farmSize || 1
    },
    validate: (data) => {
      let errors = {};
      if (!data.cultivar) {
        errors.cultivar = "Kultivar naam word vereis";
      }
      if (!data.crop) {
        errors.crop = "Gewas word vereis";
      }

      if (!data.circles) {
        errors.circles = "BLOK/KAMP No word vereis.";
      }

      //manual - inserted from custom component InputNumber
      if (!data.farmSize || data.farmSize<=0) {
        errors.farmSize = "Aanplanting grootte word vereis.";
      }
      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const farm = {
        programName: data.programName,
        cultivar: data.cultivar,
        crop: data.crop,
        farmSize: data.farmSize,
        rowWidth: rowWidth,
        circles: data.circles,
      };
     
      axios
        .post("/api/create_planting_details/" + farmerID, farm, config)
        .then((res) => {
          if (res.statusText === "OK") {
            setTimeout(() => {
              setShowSpinner(false);
              setShowSuccessMessage(true);
              props.setPlantingDetail(res.data);
            }, 1000);
          }
        });

      setFarmSizeErrorMsg(false);
      setRowWidthErrMsg(false);
    },
  });

  const getFarmers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/get_farmers", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      console.log("There was an error in ProgramDuplication.getFarmers");
    } else {
      setFarmers(data);
      data.map((item) => {
        farmerOptions.push(item.businessName);
        setFarmerOptions([...farmerOptions]);
      });
    }
  };

  const handleFarmerChange = (businessName) => {
    if (farmers.length === 0) return;
    setFarmerSelect(businessName);
    var id = farmers.filter((option) => {
      return option.businessName === businessName;
    });
    setFarmerID(id[0]["id"]);
    setEnableInputs(true);
  };

  useEffect(() => {
    getFarmers();
  }, []);

  const handleCropChange = (crop) => {
    setCropSelect(crop);
    formik.values.crop = crop;
    setValidCrop(true);
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* This card is for planting details */}

        {/* <Card style={{width:'100%'}}> */}

        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <label
            htmlFor="Program Name"
            className={classNames({
              "p-error": isFormFieldValid("programName"),
            })}
          >
            Kies 'n kliënt*
          </label>

          <span style={{ marginBottom: "2%" }}>
            <Dropdown
              style={{ width: "100%", marginTop: "2%" }}
              options={farmerOptions}
              value={farmerSelect}
              onChange={(e) => handleFarmerChange(e.target.value)}
            />
            <>
              {enableInputs === false ? (
                <small className={classNames({ "p-error": true })}>
                  Kies asb. 'n kliënt
                </small>
              ) : (
                <></>
              )}
            </>
          </span>

          <label
            htmlFor="Crop"
            className={classNames({
              "p-error": isFormFieldValid("Crop"),
            })}
          >
            Gewas*
          </label>

          <span style={{ marginBottom: "2%" }}>
            <Dropdown
              id="Crop"
              style={{ width: "100%", marginTop: "2%" }}
              options={props.crops.filter((crop)=>crop.id === props.cropId).map((crop)=>crop.name)}
              value={cropSelect}
              onChange={(e) => handleCropChange(e.target.value)}
            />
            <>
              {validCrop === false ? (
                <small className={classNames({ "p-error": true })}>
                  Kies asb. 'n gewas
                </small>
              ) : (
                <></>
              )}
            </>
          </span>

          <span style={{ marginBottom: "2%" }}>
            <label
              htmlFor="Cultivar Name"
              className={classNames({
                "p-error": isFormFieldValid("cultivar"),
              })}
            >
              Kultivar naam*
            </label>
            <span className="p-float-label">
              <InputText
                id="Cultivar Name"
                style={{ width: "100%" }}
                name="cultivar"
                disabled={!enableInputs}
                value={formik.values.cultivar}
                onChange={formik.handleChange}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("cultivar"),
                })}
              />
            </span>
            {getFormErrorMessage("cultivar")}
          </span>

          <span style={{ marginBottom: "2%" }}>
            <label
              htmlFor="Circle No"
              className={classNames({ "p-error": isFormFieldValid("circles") })}
            >
              Blok/Sirkel No.*
            </label>
            <span className="p-float-label">
              <InputText
                id="Circle No"
                style={{ width: "100%" }}
                name="circles"
                disabled={!enableInputs}
                value={formik.values.circles}
                onChange={formik.handleChange}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("circles"),
                })}
              />
            </span>
            {getFormErrorMessage("circles")}
          </span>
          <span style={{ marginBottom: "2%" }}>
            <label style={{ marginBottom: "2%" }}>
              Aanplanting grootte (Ha)*
            </label>
            <span className="p-float-label">
              <InputNumber
                id="farmSize"
                style={{ width: "100%" }}
                disabled={!enableInputs}
                maxFractionDigits={2}
                mode="decimal"
                name="farmSize"
                value={formik.values.farmSize}
                onChange={e => {
                  //manually inserting property into Formik due to this being a custom component
                  formik.setFieldValue("farmSize", e.value)
                }
                }
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("farmSize"),
                })}
                min={0}
              />
              {getFormErrorMessage("farmSize")}
            </span>
          </span>

          <span>
            <label style={{ marginBottom: "2%" }}>Plantry wydte (m)*</label>
            <span className="p-float-label">
              <InputNumber
                id="rowWidth"
                style={{ width: "100%" }}
                name="rowWidth"
                maxFractionDigits={2}
                disabled={!enableInputs}
                value={rowWidth}
                mode="decimal"
                onChange={(e) => {
                  setRowWidthErrMsg(true);
                  if (e.value !== null) {
                    setRowWidth(e.value);
                  } else {
                    setRowWidth(0);
                  }
                }}
                autoFocus
                className={classNames({
                  "p-invalid": isFormFieldValid("rowWidth"),
                })}
              />
            </span>
            <>
              {rowWidthErrMsg === true && rowWidth === 0 ? (
                <small className={classNames({ "p-error": true })}>
                  Please enter a row width.
                </small>
              ) : (
                <></>
              )}
            </>
          </span>

          {showSpinner ? <ProgressSpinner /> : <></>}
        </form>

        {/* </Card> */}
      </div>
    </div>
  );
});

export default ProgramDuplication;
