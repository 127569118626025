import { React, useEffect, useState } from "react";
import "./CheckBoxRow.css";
import "rc-slider/assets/index.css";
import { PricingAPI } from "../../../../API/PricingAPI";

const OrderVolumesValues = (props) => {
  const [, setShowSpinner] = useState(false);
  const [consolidatedOrders, setConsolidatedOrders] = useState([]);

  useEffect(() => {
    getConsolidatedOrders();
  }, []);

  const getConsolidatedOrders = async () => {
    setShowSpinner(true);
    PricingAPI.getOrderPricings(props.planDetails.id, props.orderTotalListPrice)
      .then((data) => {
        let discountTotal = 0;
        data.product_summary.forEach((item) => {
          discountTotal =
            discountTotal +
            (100 *
              (item.list_price_pv -
                item.full_order_final_price /
                  (item.full_packs * item.full_pack_volume))) /
              item.list_price_pv;
        });
        discountTotal = discountTotal / data.product_summary.length;
        data.product_summary = [
          ...data.product_summary,
          {
            AGI: null,
            discount_rate: null,
            full_order_discount: data.order_totals.full_order_discount,
            full_order_final_price: data.order_totals.full_order_final_price,
            full_order_list_price: null,
            full_pack_units: null,
            full_pack_volume: null,
            full_packs: null,
            full_quantity: null,
            full_volume: null,
            list_price_pv: "Average",

            order_final_discount: data.product_summary.reduce(
              (accum, item) => accum + item.order_final_discount,
              0
            ),
            order_final_price: data.product_summary.reduce(
              (accum, item) => accum + item.order_final_price,
              0
            ),
            order_list_price: data.product_summary.reduce(
              (accum, item) => accum + item.order_list_price,
              0
            ),
            product_name: null,
            product_pack_name: null,
            //using quantity to display MyPack Prys % in total line
            quantity:
              (100 *
                data.product_summary.reduce(
                  (accum, item) => accum + item.order_final_discount,
                  0
                )) /
              data.product_summary.reduce(
                (accum, item) => accum + item.order_list_price,
                0
              ),
            unit: null,
          },
        ];
        setConsolidatedOrders(data);
        setShowSpinner(false);
      })
      .catch(() => setShowSpinner(false));
  };
  return (
    <div className="col-12" style={{ marginTop: "1%", display: "block" }}>
      <table className="table w-75" style={{ marginLeft: "4%" }}>
        <thead>
          <tr style={{ color: "#FFFFFF", backgroundColor: "#00a352" }}>
            <th scope="col">Produk</th>
            <th scope="col">AGI</th>
            <th scope="col" className="d-none">
              SKU kode
            </th>
            <th className="text-end" scope="col">
              Verpakking
            </th>
            <th className="text-end" scope="col">
              Aantal eenhede
            </th>
            <th className="text-end" scope="col">
              Bestel volume
            </th>
            {/* <th className="text-end" scope="col">
                Volpak volume
              </th> */}
            <th className="text-end" scope="col">
              Lys waarde
            </th>
            <th className="text-end" scope="col">
              Afslag waarde
            </th>
            <th className="text-end" scope="col">
              MyPack waarde
            </th>
            <th className="text-end" scope="col">
              MyPack prys (R/L)
            </th>
          </tr>
        </thead>
        <tbody>
          {consolidatedOrders.product_summary ? (
            consolidatedOrders.product_summary.map((item) => {
              return (
                <tr
                  key={Math.random()}
                  className={`${item.AGI === null ? "showBorder" : "noBorder"}`}
                >
                  <td>
                    {item.product_name === "Totaal"
                      ? ""
                      : item.product_pack_name}
                  </td>
                  <td>{item.AGI}</td>
                  <td className="text-end"  >
                    {item.AGI == null
                      ? ""
                      : item.full_quantity !== null && item.full_quantity !== 0
                      ? (item.full_packs * item.full_pack_volume) /
                        item.full_quantity
                      : item.isParent?"1":0}{" "}
                    {item.unit !== null && item.unit.length > 1
                      ? item.unit
                      : ""} 
                  </td>

                  <td className="text-end" >
                    {item.AGI == null ? "" : item.quantity}
                  </td>

                  <td className="text-end">
                    {item.AGI == null
                      ? ""
                      : item.full_pack_units != null &&
                        item.full_pack_units !== 0
                      ? (item.full_pack_volume / item.full_pack_units) *
                        item.quantity
                      : 0}{" "}
                    {item.unit !== null && item.unit.length > 1
                      ? (item.isParent?"":item.unit)
                      : ""}
                      
                  </td>

                  {/* Lys Waarde  */}
                  <td className="text-end" style={{display: item.isParent?"none":"table-cell"}} >
                    {parseFloat(item.order_list_price)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                  </td>

                  {/* Afslag Waarde */}
                  <td className="text-end" style={{display: item.isParent?"none":"table-cell"}} >
                    {parseFloat(item.order_final_discount)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                  </td>

                  {/* MyPack Waarde */}
                  <td className="text-end" style={{display: item.isParent?"none":"table-cell"}} >
                    {parseFloat(item.order_final_price)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                  </td>

                  {/* MyPack Prys */}
                  <td className="text-end" style={{display: item.isParent?"none":"table-cell"}}>
                    {item.AGI == null ? "Korting: " : ""}
                    {(item.AGI == null
                      ? item.quantity


                      : 
                      
                      parseFloat(
                          item.full_pack_units != null &&
                            item.full_pack_units !== 0
                            ? item.order_final_price /
                                ((item.full_pack_volume /
                                  item.full_pack_units) *
                                  item.quantity)
                            : 0
                        )




                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    {item.AGI == null ? "%" : ""}
                  </td>
                </tr>
              );
            })
          ) : (
            <div></div>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrderVolumesValues;
