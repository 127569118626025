import React from 'react'
import { ProgressSpinner } from "primereact/progressspinner";
import "./LoadingSpinner.css"

const LoadingSpinner = ({loadingText ,topPercentage = 70}) => {
  return (
    <div className="d-flex flex-grow-1 justify-content-center align-items-center position-absolute w-100 h-100">
    <ProgressSpinner style={{ position: 'absolute', top: `${topPercentage}%` }} />
    <p className="spinnerText" style={{ 
        position: 'absolute', 
        top: `${topPercentage}%`,
        backgroundColor: 'rgba(0, 163, 82, 0.7)',
        color: 'white', 
        padding: '10px', 
        borderRadius: '5px'
    }}>
      
        {loadingText}
    </p>
</div>

  )
}

export default LoadingSpinner