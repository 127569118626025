import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { useEffect, useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import WhiteLogo from "../../../Images/SyngentaLogoWhite.png";
import { UserContext } from "../../../context/UserContext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import axios from "axios";
import "./AgentProfile.css";
import WeatherMapLogo from "../../../Images/LogoWeather.jpg";

const AgentProfile = (props) => {
  const [token, setToken, agent] = useContext(UserContext);
  const [showSucessMessge, setSucessMessage] = useState(false);
  const [agentCopy, setAgentCopy] = useState(JSON.parse(JSON.stringify(agent)));
  const [, setShowSpinner] = useState(false);
  const [, setFormData] = useState({});
  const [, setShowMessage] = useState(false);
  const [, setShowSuccessMessage] = useState(false);
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [merchantID, setMerchantID] = useState(null);
  const [userRoles] = useState(props.userRoles);
  const [isAgent] = useState(userRoles && userRoles.find((i) => i === "AGENT") ? true : false);

  useEffect(() => {
    getAgent(agent.id);
    getMerchants();
  }, []);

  useEffect(() => {
    loadInitialFormValues();
  }, [agentCopy]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
  };

  const loadInitialFormValues = () => {
    formik.setValues(agentCopy);
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const formik = useFormik({
    initialValues: {
      contactNo: "",
      email: "",
      name: "",
      postBox: "",
      postalCode: "",
      region: "",
      registered: "",
      salesManager: "",
      street: "",
      town: "",
      merchant_id: null,
      depot: "",
      branch: "",
      sapAccount: "",
      sapAccountRel: "",
    },

    validate: (data) => {
      let errors = {};
      setFormData(data);
      if (!data.contactNo) {
        errors.contactNo = "Kontak nommer vereis.";
      }
      if (!data.email) {
        errors.email = "Epos vereis.";
      }
      if (!data.name) {
        errors.name = "Naam vereis.";
      }
      if (!data.region) {
        errors.region = "Streek vereis.";
      }
      if (!data.town) {
        errors.town = "Dorp vereis.";
      }
      if (isAgent && !data.merchant_id) {
        errors.merchant_id = "Handelaar vereis.";
      }

      return errors;
    },

    onSubmit: (data) => {
      setFormData(data);
      setShowMessage(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
    
      const body = {
        email: data.email,
        name: data.name,
        region: data.region,
        contactNo: data.contactNo,
        street: data.street,
        postBox: data.postBox,
        town: data.town,
        postalCode: data.postalCode,
        registered: data.registered,
        salesManager: data.salesManager,
        merchant_id: data.merchant_id,
        depot: data.depot,
        branch: data.branch,
        id: -1,
      };
      axios.put(`/api/agent/` + agent.id, body, config).then((res) => {
        if (res.statusText !== "OK") {
          console.log("There is an error in Agent Profile");
        } else {
          setSucessMessage(true);
          setTimeout(() => {
            setTimeout(() => {
              setShowSpinner(false);
              setShowSuccessMessage(true);
              props.setPath("pastOrders");
            }, 2000);
          }, 1000);
        }
      });
    },
  });

  const getAgent = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/get_agent/" + id,

      requestOptions
    );
    const data = await response.json();
  
    if (!response.ok) {
      console.log("There is an error on the AgentProfile page fetching agent data");
    } else {
      setAgentCopy(data);
      setMerchantID(data.merchant_id);
    }
  };

  const handleMerchantChange = (id) => {
    let temp = agentCopy;
    temp.merchant_id = id;
    setAgentCopy(temp);
    setMerchantID(id);
  };

  const getMerchants = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_merchants/", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      console.log("There is an error");
    } else {
      setMerchantOptions([...data]);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          color: "#FFFFFF",
          backgroundColor: "#00a352",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <Button
          style={{
            marginLeft: "5%",
            fontSize: "20px",
            backgroundColor: "#00a352",
            borderColor: "#00a352",
          }}
          icon="pi pi-arrow-left"
          onClick={(e) => {
            props.setPath("pastOrders");
          }}
        />
        <p className={`mb-0 text-nowrap`} style={{ marginLeft: "2%", fontSize: "20px" }}>
          MY PROFIEL
        </p>
        <div
          style={{
            width: "85%",
            color: "#FFFFFF",
            height: "30px",
            backgroundColor: "#00a352",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          <img src={WhiteLogo} alt="" style={{ height: "100%", marginRight: "6%" }} />
        </div>
      </div>

      <div
        style={{
          margin: "1%",
          width: "90%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <Card
                style={{
                  width: "35vw",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <form className="row g-3" id="agentForm" onSubmit={formik.handleSubmit}>
                  <div className="col-lg-6">
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText
                        id="Name"
                        style={{ width: "100%" }}
                        name="name"
                        type="text"
                        {...formik.getFieldProps("name")}
                        autoFocus
                        className={classNames({
                          "p-invalid": isFormFieldValid("name"),
                        })}
                      />
                      <label
                        htmlFor="Name"
                        className={classNames({
                          "p-error": isFormFieldValid("name"),
                        })}
                      >
                        NAAM
                      </label>

                      {getFormErrorMessage("name")}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText
                        id="Email"
                        style={{ width: "100%" }}
                        name="email"
                        type="text"
                        {...formik.getFieldProps("email")}
                        className={classNames({
                          "p-invalid": isFormFieldValid("email"),
                        })}
                      />
                      <label
                        htmlFor="Email"
                        className={classNames({
                          "p-error": isFormFieldValid("email"),
                        })}
                      >
                        EPOS
                      </label>

                      {getFormErrorMessage("email")}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText
                        id="ContactNumber"
                        style={{ width: "100%" }}
                        name="ContactNumber"
                        type="text"
                        {...formik.getFieldProps("contactNo")}
                        className={classNames({
                          "p-invalid": isFormFieldValid("contactNo"),
                        })}
                      />
                      <label
                        htmlFor="ContactNumber"
                        className={classNames({
                          "p-error": isFormFieldValid("contactNo"),
                        })}
                      >
                        KONTAK NOMMER
                      </label>
                      {getFormErrorMessage("contactNo")}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText
                        id="Region"
                        style={{ width: "100%" }}
                        name="region"
                        type="text"
                        {...formik.getFieldProps("region")}
                        className={classNames({
                          "p-invalid": isFormFieldValid("region"),
                        })}
                      />
                      <label
                        htmlFor="Region"
                        className={classNames({
                          "p-error": isFormFieldValid("region"),
                        })}
                      >
                        STREEK
                      </label>

                      {getFormErrorMessage("region")}
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText
                        id="Town"
                        style={{ width: "100%" }}
                        name="Town"
                        type="text"
                        {...formik.getFieldProps("town")}
                        className={classNames({
                          "p-invalid": isFormFieldValid("town"),
                        })}
                      />
                      <label
                        htmlFor="Town"
                        className={classNames({
                          "p-error": isFormFieldValid("town"),
                        })}
                      >
                        DORP
                      </label>

                      {getFormErrorMessage("town")}
                    </span>
                  </div>

                  <div className="col-lg-6">
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText id="SalesManager" style={{ width: "100%" }} name="SalesManager" type="text" {...formik.getFieldProps("salesManager")} />
                      <label htmlFor="Name">VERKOOPSBESTUURDER</label>
                    </span>
                  </div>
                  

                  <div className={`col-lg-6 ${isAgent ? "" : "d-none"}`}>
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <Dropdown
                        optionLabel="name"
                        optionValue="id"
                        name="merchant"
                        style={{ width: "100%", marginTop: "2%" }}
                        options={merchantOptions}
                        placeholder=""
                        value={merchantID}
                        onChange={(e) => handleMerchantChange(e.target.value)}
                        disabled="true"
                      />

                      <label
                        htmlFor="merchant"
                        className={classNames({
                          "p-error": isFormFieldValid("merchant_id"),
                        })}
                      >
                        HANDELAAR
                      </label>

                      {getFormErrorMessage("merchant_id")}
                    </span>
                  </div>

                  <div className={`col-lg-6 ${isAgent ? "" : "d-none"}`}>
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText id="Depot" style={{ width: "100%", marginTop: "2%" }} name="Depot" type="text" {...formik.getFieldProps("depot")} />
                      <label htmlFor="Name">DEPOT</label>
                    </span>
                  </div>

                  <div className={`col-lg-6 ${isAgent ? "" : "d-none"}`}>
                    <span className="p-float-label" style={{ marginBottom: "5vh" }}>
                      <InputText id="Branch" style={{ width: "100%", marginTop: "2%" }} name="Branch" type="text" {...formik.getFieldProps("branch")} />
                      <label htmlFor="Branch">BRANCH</label>
                    </span>
                  </div>

                  <div className="col-lg-6 no-margin-top">
                  <label className="ms-3">REKENING</label>
                    <span className="p-float-label border p-2" style={{ marginBottom: "5vh" }}>
                      <span id="SapAccount" style={{ width: "100%" }}>
                      {formik.values?.sapAccountRel?.name}
                      </span>
                      
                    </span>
                    
                  </div>

                  {showSucessMessge ? (
                    <>
                      <Message style={{ marginTop: "0%", width: "100%" }} severity="success" text="Profiel is suksesvol opgedateer" />
                    </>
                  ) : (
                    <>
                      <Button
                        type="submit"
                        form="agentForm"
                        style={{
                          width: "35vw",
                          marginTop: "2vh",
                          backgroundColor: "#00a352",
                          borderColor: "#00a352",
                        }}
                        label="OPDATEER"
                      ></Button>
                    </>
                  )}
                </form>
              </Card>
            </div>

            <div className={`col-sm-6 ${agentCopy.registered === "true" ? "" : "d-none"}`}>
              <div className="container">
                <div className="row mt-4">
                  <div className="col-lg-auto">
                    <img src={WeatherMapLogo} alt="" style={{ width: "40px" }} />
                  </div>
                  <div className={`col-lg text-start`}>
                    <h5>
                      Jy is geregistreerd vir die 'Weather and Risk Map' diens en kwalifiseer addisionele korting op die MyPack aankope. <br></br>
                      <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">
                        https://www.syngenta.co.za/service/weather-and-risk-map-service
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className={`col-sm-6 ${agentCopy.registered !== "true" ? "" : "d-none"}`}>
              <div className="container">
                <div className="row mt-4">
                  <div className="col-lg-auto">
                    <img src={WeatherMapLogo} alt="" style={{ width: "40px" }} />
                  </div>
                  <div className={`col-lg text-start`}>
                    <h5>
                      Teken gratis in vir Syngenta se 'Weather and Risk Map' diens om vir 'n addisionele korting te kwalifiseer. <br></br>
                      <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">
                        https://www.syngenta.co.za/service/weather-and-risk-map-service
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgentProfile;
