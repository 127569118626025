import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import LoginImageLeft from '../../../Images/PasMak.webp'
import FooterBanner  from '../../../Images/FooterBanner.png'
import './AgentLogin.css'
import AgentAPI from "../../../API/AgentAPI";
import { ProgressSpinner } from "primereact/progressspinner";


const AgentLogin = (props)=>{
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [,setToken] = useContext(UserContext)
    const [agentDetail, setAgentDetail] = useState(props.agentDetail)
    const [showSpinner, setShowSpinner] = useState(false);
    
  useEffect(() => {
    getParentDetails(agentDetail?.id);
  }, [agentDetail]);

    const submitLogin = async () =>{
        const requestOptions = {
            method:"POST",
            headers:{"Content-Type":"application/x-www-form-urlencoded"},
            body: JSON.stringify(`grant_type=&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&scope=&client_id=&client_secret=`)
        };

        const response= await fetch('/api/token', requestOptions)
        const data = await response.json()

        if (!response.ok) {
            setErrorMessage('Epos of wagwoord is verkeerd');
          } else {
            props.setAgentDetail(null)
            setToken(data.access_token);
            setAgentDetails(data.access_token);
          }
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        submitLogin()
    }

    const setAgentDetails = async (token) => {
      const requestOptions = {
            headers:{
                "Content-Type":"application/json",
                Authorization:"Bearer "+token,
        },
        };

        const response= await fetch('/api/agents/auth_agent', requestOptions)
        const data = await response.json()
        if (response.ok) {
            props.setAgentDetail(data);
            setAgentDetail(data);
            if(data.id){
              setAgentRoles(data.id);
            }
          } 
    }

    const setAgentRoles = async(agentId)=>{
      setShowSpinner(true);      
      AgentAPI.getRolesById(
            agentId
          ).then((data) => {
            props.setAgentRoles([...data.map((i) => i.roleRel)]);
            setShowSpinner(false);
          }).catch(()=>setShowSpinner(false));
    }

    const getParentDetails = async (id) =>{
        if(!id){
            props.setParentDetail(null)
            return
        }
        const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + id,
            },
          };
          const response = await fetch(
            "/api/get_agent/" + id,
      
            requestOptions
          );
          const data = await response.json();
      
          if (!response.ok) {
            console.log(
              "There is an error on the AgentLogin page fetching agent data"
            );
          } else {
            props.setParentDetail(data)
          }
    }
    
    const loginDivStyle= {display:'flex', flexDirection:'column',width:'50%',height:'100%',    justifyContent:'center', alignItems:'center', alignContent:'center', alignSelf:'center'}

    if (showSpinner)
    return (
      <>
        <div className="d-flex flex-column min-vh-100 min-vw-100">
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );

    return (
        
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center', alignSelf:'center', width:'100%', height:'100vh'}}>
        <div style={{display:'flex',width:'80%',flexDirection:'row', justifyContent:'center', alignItems:'flex-end', alignContent:'center', alignSelf:'center'}}>
        <div style={{width:'50%', height:'100%'}}>
        <img src={LoginImageLeft} className="imgStyle" alt="" />
        </div>
        <div style={loginDivStyle} >
        <h1>TEKEN IN</h1>
        <form onSubmit={handleSubmit} style={{width:'50%'}}>
            
                <span className="p-float-label" style={{marginTop:'5%',marginBottom:'13%'}} >
                    <InputText id="Email" style={{width:'100%'}} value={email} onChange={(e) => setEmail(e.target.value)} />
                    <label htmlFor="Email">EPOS</label>
                </span>
                <span className="p-float-label" style={{marginBottom:'5%'}} >
                    <InputText id="Password" type='password'style={{width:'100%'}} value={password} onChange={(e) => setPassword(e.target.value)} />
                    <label htmlFor="Password">WAGWOORD</label>
                </span>

                    <p style={{color:'red', marginBottom:'2vh'}}>{errorMessage}</p>

                <Button style={{width:'100%', backgroundColor:'#00a352', borderColor:'#00a352'}} type="submit" label="TEKEN IN" />
                
                <div style={{display:'flex',width:'100%',flexDirection:'row', justifyContent:'center', alignItems:'flex-end', alignContent:'center', alignSelf:'center'}}>
                    <p className='small-info'>Die wagwoord kan verkry word van die Verkoops Bestuurder.​</p>
                </div>
        </form>   
        </div>
       
        </div>
         <img src={FooterBanner} style={{ width:'80.5%', marginTop:'-0.5%'}} alt="" />
         <div style={{width:'80.5%', backgroundColor:'#745731', height:'5vh'}}>

         </div>
         </div>
        
    );
}

export default AgentLogin
