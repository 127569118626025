import React, { useContext, useEffect, useRef, useState } from "react";
import PlanView from "./components/UI/PlanView/PlanView";
import OrderView from "./components/UI/OrderView/OrderView";
import FarmerReg from "./components/UI/Farmer Registration/FarmerReg";
import AgentLogin from "./components/UI/Agent Login/AgentLogin";
import { UserContext } from "./context/UserContext";
import PastOrders from "./components/UI/Past Orders/PastOrders";
import ProgramDuplication from "./components/UI/Past Orders/ProgramDuplication";
import AgentProfile from "./components/UI/Agent Profile/AgentProfile";
import CreatePlanView from "./components/UI/PlanView/Components/CreatePlanView/CreatePlanView";
import PriceView from "./components/UI/PricingView/PricingView";
import HelpView from "./components/UI/Help/HelpView";
import SubmitConsolidatedOrderView from "./components/UI/OrderView/Consolidated Orders/SubmitConsolidatedOrderView";
import ConsolidatedOrderView from "./components/UI/OrderView/Consolidated Orders/ConsolidatedOrderView";
import OrderListView from "./components/UI/OrderView/OrderListView";
import GrowerListView from "./components/UI/Growers/GrowerListView";
import Modal from "./components/UI/Modal/Modal";
import WeatherMapLogo from "./Images/LogoWeather.jpg";
import IndividualProgramView from "./components/UI/OrderView/Consolidated Orders/IndividualProgramView";
import PricingDashboardView from "./components/UI/PricingView/PricingDashboardView";
import CropSelectionView from "./components/UI/Crop Selection/CropSelectionView";
import CropsAPI from "./API/CropsAPI";
import { Button } from "primereact/button";

const App = () => {
  const [token, , agent] = useContext(UserContext);
  const [agentRoles, setAgentRoles] = useState([]);
  const [path, setPath] = useState("pastOrders");
  const [backPath, setBackPath] = useState(null);
  const [products, setProducts] = useState([]);
  const [choices, setChoices] = useState([]);
  const [planDetails, setPlanDetails] = useState(null);
  const [planDetailsPrevious, setPlanDetailsPrevious] = useState(null);
  const [agentDetail, setAgentDetail] = useState(null);
  const [parentDetail, setParentDetail] = useState(null);
  const [orderObj, setOrderObj] = useState({
    farmerID: null,
    plantingID: null,
    farmSize: null,
    rowWidth: null,
    crop: "",
    plantingDate: "",
    products: {},
  });
  const [, setMessage] = useState("");
  const [consolidatedOrderHeader, setConsolidatedOrderHeader] = useState(null);
  const [filterPlan, setFilterPlan] = useState(null);
  const [farm, setFarm] = useState(null);
  const [openNotRegisteredMessage, setOpenNotRegisteredMessage] = useState(false);
  const [openConfirmationMessage, setOpenConfirmationMessage] = useState(false);
  const [confirmationButtonLabels, setConfirmationButtonLabels] = useState(["", ""]);
  const [openRegisteredMessage, setOpenRegisteredMessage] = useState(false);
  const [showCsvFailed, setShowCsvFailed] = useState(false);
  const [costObj, setCostObj] = useState({});
  const [merchant, setMerchant] = useState(null);
  const [orderTotalListPrice, setOrderTotalListPrice] = useState(null);
  const [cropId, setCropId] = useState(1);
  const [crops, setCrops] = useState(null);
  const [periodsPerCrops, setPeriodsPerCrops] = useState(null);
  const localStorage = window.localStorage;
  const [allowAction, setAllowAction] = useState(false);
  const [actionHeader, setActionHeader] = useState(null);
  const [actionDetail, setActionDetail] = useState(null);
  const [farmToDelete, setFarmToDelete] = useState(null);
  
  const dialogRef = useRef();

  const actionDialogFunctionOnChild = (allow) => {
    if (allow) {
      dialogRef.current?.deleteFarmer(farmToDelete.id);
    }
  };

  useEffect(() => {
    if (localStorage.getItem(agent.email) === undefined) {
      setPath("cropSelectionView");
    } else {
        const cropIdAsString = localStorage.getItem(agent.email);
        let tempCropId = 1;


        if (!cropIdAsString || isNaN(cropIdAsString)) {
          console.log(`Failed to retrieve valid Crop Id for ${agent.email} from local storage`);
      } else tempCropId = parseInt(cropIdAsString, 10);
      setCropId(tempCropId);
    }
  }, [agent.email,localStorage]);

  useEffect(() => {
    if (agentRoles.length > 0 && ["SALES MANAGER", "AREA MANAGER", "CAMPAIGN LEAD"].includes(agentRoles[0].name)) {
        setPath("orderListView");
      } else {
        setPath("pastOrders");
      }
    
  }, [agentRoles]);

  const getWelcomeMessage = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch("/api", requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log("Error connecting to backend");
    } else {
      setMessage(data.message);
    }
  };

  const getProducts = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_all_products", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setProducts([...data]);
    }
  };

  const getAllChoices = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/all_choices/", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setChoices([...data]);
    }
  };

  const getAllCrops = async () => {
    CropsAPI.getAllCrops().then((data) => {
      setCrops(data);
    });
  };

  const getPeriodsPerCrop = async function () {
    CropsAPI.getPeriodsPerCrop().then((data) => {
      if (data)
        {
          console.log("Periods retrieved equals "+data.length)}
      setPeriodsPerCrops(data);
    });
  };

  useEffect(() => {
    getWelcomeMessage();
    getProducts();
    getAllChoices();
    getAllCrops();
    getPeriodsPerCrop();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (agentDetail == null)
    return (
      <div>
        <AgentLogin setAgentDetail={setAgentDetail} agentDetail={agentDetail} setParentDetail={setParentDetail} setAgentRoles={setAgentRoles} />
      </div>
    );

  return (
    <div className="accordion-demo">
      {token == null ? (
        <AgentLogin setAgentDetail={setAgentDetail} setParentDetail={setParentDetail} />
      ) : (
        <div>
          <div>
            {/* ToDo - use only 1 modal and send in the messages as a parameter */}
            <Modal open={openNotRegisteredMessage} onClose={() => setOpenNotRegisteredMessage(false)}>
              <div className="container">
                <div className="row">
                  <div className="col col-lg-2">
                    <img src={WeatherMapLogo} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="col col-lg-10">
                    <h5 style={{ fontSize: "1.5rem" }}>
                      Teken gratis in vir Syngenta se 'Weather and Risk Map' diens om vir 'n addisionele korting te kwalifiseer.'
                    </h5>
                    <p style={{ fontSize: "1.25rem" }}>Kontak jou Syngenta verteenwoordiger vir meer inligting.</p>
                    <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">
                      https://www.syngenta.co.za/service/weather-and-risk-map-service
                    </a>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal open={openRegisteredMessage} onClose={() => setOpenRegisteredMessage(false)}>
              <div className="container">
                <div className="row">
                  <div className="col col-lg-2">
                    <img src={WeatherMapLogo} alt="" style={{ width: "100%" }} />
                  </div>
                  <div className="col col-lg-10">
                    <h5 style={{ fontSize: "1.5rem" }}>
                      Jy is geregistreerd vir die 'Weather and Risk Map' diens en kwalifiseer addisionele korting op die MyPack aankope.
                    </h5>
                    <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">
                      https://www.syngenta.co.za/service/weather-and-risk-map-service
                    </a>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal open={showCsvFailed} onClose={() => setShowCsvFailed(false)}>
              <div className="container">
                <div className="row">
                  <div className="col col-lg-2"></div>
                  <div className="col col-lg-10">
                    <h5 className="text-danger" style={{ fontSize: "1.5rem" }}>
                      Die stuur van die kwotasie was nie suksesvol nie.
                    </h5>
                    <p style={{ fontSize: "1.25rem" }}></p>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal open={openConfirmationMessage} onClose={() => setOpenConfirmationMessage(false)}>
              <div className="container">
                <div className="row">
                  <div className="col col-lg-10">
                    <h5 style={{ fontSize: "1.5rem" }}>{actionHeader}</h5>
                    <p style={{ fontSize: "1.25rem" }}>{actionDetail}</p>
                    <Button
                      style={{minWidth: "52px"}}
                      className="d-block text-center w-25 button white_order_button p-button-label p-c m-1 archiving_navigation_buttons float-end"
                      onClick={() => {
                        setOpenConfirmationMessage(false);
                        actionDialogFunctionOnChild(allowAction);
                      }}
                    >
                      {/* Yes */}
                      {confirmationButtonLabels[0]}
                    </Button>
                    <Button
                      style={{minWidth: "52px"}}
                      className={`${
                        confirmationButtonLabels.length > 1 ? "" : "d-none"
                      } d-block text-center w-25 button white_order_button p-button-label p-c m-1 archiving_navigation_buttons float-end`}
                      onClick={() => {
                        setOpenConfirmationMessage(false);
                      }}
                    >
                      {/* No */}
                      {confirmationButtonLabels[1]}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          {path === "pastOrders" ? (
            <PastOrders
              agentDetail={agentDetail}
              setAgentDetail={setAgentDetail}
              setPath={setPath}
              setBackPath={setBackPath}
              setPlanDetails={setPlanDetails}
              planDetails={planDetails}
              setAgentRoles={setAgentRoles}
              setFarm={setFarm}
              filterPlan={filterPlan}
              userRoles={agentRoles.map((i) => i.name)}
              cropId={cropId}
              setCropId={setCropId}
              crops={crops}
              setOpenConfirmationMessage={setOpenConfirmationMessage}
            ></PastOrders>
          ) : (
            <></>
          )}
          {path === "farmerReg" ? (
            <FarmerReg setPath={setPath} backPath={backPath} farm={farm} setFarm={setFarm} setOpenConfirmationMessage={setOpenConfirmationMessage}></FarmerReg>
          ) : (
            <></>
          )}
          {path === "programDuplication" ? (
            <ProgramDuplication setPath={setPath} costObj={costObj} setCostObj={setCostObj} crops={crops} cropId={cropId}></ProgramDuplication>
          ) : (
            <></>
          )}
          {path === "agentProfile" ? (
            <AgentProfile
              setPath={setPath}
              costObj={costObj}
              setCostObj={setCostObj}
              setOpenNotRegisteredMessage={setOpenNotRegisteredMessage}
              setOpenRegisteredMessage={setOpenRegisteredMessage}
              setShowIsIsSucess={setShowCsvFailed}
              userRoles={agentRoles.map((i) => i.name)}
            ></AgentProfile>
          ) : (
            <></>
          )}
          {path === "createPlanView" ? (
            <CreatePlanView setPath={setPath} setPlanDetails={setPlanDetails} cropId={cropId} crops={crops}></CreatePlanView>
          ) : (
            <></>
          )}
          {path === "planView" ? (
            <PlanView
              planDetails={planDetails}
              setPath={setPath}
              costObj={costObj}
              setCostObj={setCostObj}
              orderObj={orderObj}
              setOrderObj={setOrderObj}
              products={products}
              choices={choices}
              cropId={cropId}
              periodsPerCrops={periodsPerCrops}
            ></PlanView>
          ) : (
            <></>
          )}
          {path === "orderView" ? (
            <OrderView
              setPath={setPath}
              planDetails={planDetails}
              agent={agent}
              costObj={costObj}
              setCostObj={setCostObj}
              setBackPath={setBackPath}
              agentDetail={agentDetail}
              cropId={cropId}
            ></OrderView>
          ) : (
            <></>
          )}
          {path === "priceView" ? (
            <PriceView
              setPath={setPath}
              planDetails={planDetails}
              agent={agent}
              costObj={costObj}
              setCostObj={setCostObj}
              setShowIsIsSucess={setShowCsvFailed}
              backPath={backPath}
              planDetailsAll={planDetailsPrevious}
              setPlanDetails={setPlanDetails}
            />
          ) : (
            <></>
          )}
          {path === "helpView" ? <HelpView setPath={setPath} planDetails={planDetails} agent={agent} costObj={costObj} setCostObj={setCostObj} /> : <></>}
          {path === "submitConsolidatedOrderView" ? (
            <SubmitConsolidatedOrderView
              setPath={setPath}
              planDetails={planDetails}
              setPlanDetails={setPlanDetails}
              agentDetail={agentDetail}
              setAgentDetail={setAgentDetail}
              parentDetail={parentDetail}
              setParentDetail={setParentDetail}
              costObj={costObj}
              setCostObj={setCostObj}
              userRoles={agentRoles.map((i) => i.name)}
              setFilterPlan={setFilterPlan}
              setBackPath={setBackPath}
              setPlanDetailsPrevious={setPlanDetailsPrevious}
              planDetailsPrevious={planDetailsPrevious}
              cropId={cropId}
            />
          ) : (
            <></>
          )}
          {path === "consolidatedOrderView" ? (
            <ConsolidatedOrderView
              setPath={setPath}
              planDetails={planDetails}
              setPlanDetails={setPlanDetails}
              agentDetail={agentDetail}
              setAgentDetail={setAgentDetail}
              parentDetail={parentDetail}
              setParentDetail={setParentDetail}
              costObj={costObj}
              setCostObj={setCostObj}
              userRoles={agentRoles.map((i) => i.name)}
              consolidatedOrderHeader={consolidatedOrderHeader}
              setBackPath={setBackPath}
              setPlanDetailsPrevious={setPlanDetailsPrevious}
              planDetailsPrevious={planDetailsPrevious}
              setFilterPlan={setFilterPlan}
              setMerchant={setMerchant}
              setOrderTotalListPrice={setOrderTotalListPrice}
            />
          ) : (
            <></>
          )}
          {path === "orderListView" ? (
            <OrderListView
              setPath={setPath}
              agentDetail={agentDetail}
              userRoles={agentRoles.map((i) => i.name)}
              setConsolidatedOrderHeader={setConsolidatedOrderHeader}
              consolidatedOrderHeader={consolidatedOrderHeader}
              crops={crops}
              setCropId={setCropId}
              cropId={cropId}
            />
          ) : (
            <></>
          )}
          {path === "individualProgramView" ? (
            <IndividualProgramView
              setPath={setPath}
              backPath={backPath}
              planDetails={planDetails}
              products={products}
              agentDetail={agentDetail}
              parentDetail={parentDetail}
              consolidatedOrderHeader={consolidatedOrderHeader}
              merchant={merchant}
              choices={choices}
              orderTotalListPrice={orderTotalListPrice}
              cropId={cropId}
            />
          ) : (
            <></>
          )}
          {path === "growerListView" ? (
            <GrowerListView
              setPath={setPath}
              setBackPath={setBackPath}
              agentDetail={agentDetail}
              userRoles={agentRoles.map((i) => i.name)}
              setConsolidatedOrderHeader={setConsolidatedOrderHeader}
              consolidatedOrderHeader={consolidatedOrderHeader}
              setFarm={setFarm}
              farm={farm}
              setOpenConfirmationMessage={setOpenConfirmationMessage}
              allowDelete={allowAction}
              setAllowDelete={setAllowAction}
              setActionHeader={setActionHeader}
              setActionDetail={setActionDetail}
              ref={dialogRef}
              setConfirmationButtonLabels={setConfirmationButtonLabels}
              setFarmToDelete={setFarmToDelete}
            />
          ) : (
            <></>
          )}
          {path === "pricingDashboardView" ? <PricingDashboardView setPath={setPath} backPath={backPath} agentDetail={agentDetail} /> : <></>}
          {path === "cropSelectionView" ? <CropSelectionView setPath={setPath} backPath={backPath} agent={agentDetail} setCropId={setCropId} /> : <></>}
        </div>
      )}
    </div>
  );
};

export default App;
