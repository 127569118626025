import React, { useState, useEffect } from "react";
import Header from "../../Header";
import PricingAPI from "../../../API/PricingAPI";
import "./PricingView.css";

const PricingDashboardView = (props) => {
  const [pricings, setPricings] = useState([]);
  const [, setShowSpinner] = useState(false);
  const [filter,setFilter] = useState("");

  useEffect(() => {
    getPricing2();
  }, []);

  const handleSearchChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredPricings = pricings.filter(item=> item.product_pack_name.toLowerCase().includes(filter.toLowerCase()))
  
  const getPricing2 = () => {
    setShowSpinner(true);
    PricingAPI.getAllPricing2Products()
      .then((data) => {
        setPricings(data);
        setShowSpinner(false);
      })
      .catch(() => setShowSpinner(false));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header
        title={"Pryse".toUpperCase()}
        backPath={"pastOrders"}
        setPath={props.setPath}
      />
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-1">
            <h5 className="dasboard_header_labels">Product</h5>
          </div>
          <div className="col-sm-6">
            <input className="dashboard_header_input" value={filter} onChange={handleSearchChange}></input>
          </div>
        </div>
        <div
          className="row mb-3"
          style={{
            color: "rgb(255, 255, 255)",
            backgroundColor: "rgb(0, 163, 82)",
            height: "50px",
          }}
        >
          <div className="col-sm-1">
            <h5>Crop</h5>
          </div>
          <div className="col-sm-3">
            <h5>Product</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="float-end">List Price</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="text-end">Season Price</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="text-end">Tier1 Discount%</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="text-end">Tier2 Discount%</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="text-end">Tier3 Discount%</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="text-end">Tier1 List Price</h5>
          </div>
          <div className="col-sm-1">
            <h5 className="text-end">Tier2 List Price</h5>
          </div>
        </div>
        {
        
        filteredPricings
          .sort((a, b) => a.product_pack_name < b.product_pack_name)
          .map((item,) => {
            return (
              <div className="row">
                <div className="col-sm-1">
                  <label>{item.crop_name}</label>
                </div>

                <div className="col-sm-3">
                  <label>{item.product_pack_name}</label>
                </div>
                
                <div className="col-sm-1">
                  <label className="float-end text-nowrap">
                    {item.list_price_pv !== null
                      ? item.list_price_pv
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : 0}
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="float-end text-nowrap">
                    {item.season_price_pv !== null
                      ? item.season_price_pv
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : ""}
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="float-end">
                    {item.tier1_discount_pct !== null
                      ? item.tier1_discount_pct
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : 0}
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="float-end">
                  {item.tier2_discount_pct !== null
                      ? item.tier2_discount_pct
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : 0}
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="float-end">
                  {item.tier3_discount_pct !== null
                      ? item.tier3_discount_pct
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : 0}
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="float-end">
                    {item.tier1_price_pv !== null
                      ? item.tier2_price_pv
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : 0}
                  </label>
                </div>
                <div className="col-sm-1">
                  <label className="float-end">
                    {item.tier2_price_pv !== null
                      ? item.tier2_price_pv
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : 0}
                  </label>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PricingDashboardView;
