//TD - not working on server - to revisit
export const Token = localStorage.getItem("agentToken");

export const PricingAPI = {
  getOrderPricings: async (planDetailsIds = [], orderTotalListPrice = null) => {
      let url = `/api/order_pricing/${JSON.stringify(planDetailsIds)}/null`;
      if(orderTotalListPrice){
        url = url +  (orderTotalListPrice ? "?order_total_invoice=" + orderTotalListPrice : null);
      }
      return await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("agentToken"),
          },
        }
      )
      .then((response) => {
        if (response.ok) return response.json();
      })
      .catch((error) => {
        console.error("There was an error in PricingAPI.getOrderPricings", error);
      });
  },

  getAllPricing2Products: async () => {
    try {
      const res = await fetch(
        "/api/get_all_pricing2_products", 
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("agentToken"),
          },
        }
      );
      const result = await res.json();
      return result;
    } catch (e) {
      console.log("There was an error in PricingAPI.getAllPricing2Products");
      return [];
    }
  },
};

export default PricingAPI;
