import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import "../OrderView.css";
import "./ConsolidatedOrder.css";
import Header from "../../../Header";
import { PricingAPI } from "../../../../API/PricingAPI";
import { AgentAPI } from "../../../../API/AgentAPI";
import { ProgressSpinner } from "primereact/progressspinner";

const ConsolidatedOrderView = (props) => {
  const [orderHeaderAgentDetail, setOrderHeaderAgentDetail] = useState(
    props.consolidatedOrderHeader.agent_id === props?.agentDetail?.id
      ? props?.agentDetail
      : null
  );

  const [token] = useContext(UserContext);
  const [consolidatedOrders, setConsolidatedOrders] = useState([]);
  const [consolidatedOrderPlans, setConsolidatedOrderPlans] = useState([]);
  const [, setConsolidatedOrderProducts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    getOrderHeaderAgentDetails(props.consolidatedOrderHeader.agent_id);
    getConsolidatedOrderPlans();
  }, []);

  useEffect(() => {
    if (
      consolidatedOrderPlans !== undefined &&
      consolidatedOrderPlans.length > 0
    )
      getConsolidatedOrders(consolidatedOrderPlans);
    else setConsolidatedOrderProducts([]);
  }, [consolidatedOrderPlans]);

  const getOrderHeaderAgentDetails = async (id) => {
    if (id == null) return;
    //sales manager can see agent's orders. Signed in agent differs from agent on order then
    await AgentAPI.getAgentDetails(id).then((data) => {
      var agent = data;
      setOrderHeaderAgentDetail(agent);
      getParentAgentDetails(agent.parent_id);
    });
  };

  var getParentAgentDetails = async (id) => {
    if (id == null) return;
    await AgentAPI.getAgentDetails(id).then((data) => {
      var result = data;
      props.setParentDetail(result);
    });
  };

  const getConsolidatedOrders = async (consolidatedOrderPlans) => {
    if (
      consolidatedOrderPlans === null ||
      consolidatedOrderPlans.length === 0
    ) {
      setConsolidatedOrders(null);
      return;
    }
    setShowSpinner(true);
    PricingAPI.getOrderPricings(consolidatedOrderPlans.map((i) => i.planDetails_id))
      .then((data) => {
        data.plans = [
          ...data.plans,
          {
            circles: null,
            crop: null,
            cultivar: null,
            farmer_business_name: null,
            farmer_contact_person: null,
            farmer_email: null,
            farmer_farm_name: "Totaal",
            planting_date: null,
            planting_detail_id: null,
            products: null,
            registered: null,
            farmSize: null,
            planName: null,
            plan_add_weather_discount: 0,
            plan_id: null,
            plan_total_discount: data.order_totals.order_final_discount,
            plan_total_discount_price: data.order_totals.order_final_price,
            plan_total_discount_price_weather: 0,
            plan_total_discount_weather: 0,
            plan_total_list_price: data.order_totals.order_list_price,
          },
        ];

        let discountTotal = 0;
        data.product_summary.forEach((item) => {
          discountTotal =
            discountTotal +
            (100 *
              (item.list_price_pv -
                item.full_order_final_price /
                  (item.full_packs * item.full_pack_volume))) /
              item.list_price_pv;
        });
        discountTotal = discountTotal / data.product_summary.length;

        data.product_summary = [
          ...data.product_summary,
          {
            AGI: null,
            discount_rate: null,
            full_order_discount: data.order_totals.full_order_discount,
            full_order_final_price: data.order_totals.full_order_final_price,
            full_order_list_price: data.order_totals.full_order_list_price,
            full_pack_units: null,
            full_pack_volume: null,
            full_packs: null,
            full_quantity: null,
            full_volume: null,
            list_price_pv: "Average",
            order_final_discount: discountTotal,
            order_final_price: null,
            order_list_price: null,
            product_name: null,
            product_pack_name: null,
            quantity: null,
            unit: null,
            isParent: null
          },
        ];
        setConsolidatedOrders(data);
        setShowSpinner(false);
      })
      .catch(() => setShowSpinner(false));
  };

  const getConsolidatedOrderPlans = async () => {
    if (
      props.planDetailsPrevious != null &&
      props.planDetailsPrevious.length > 0
    ) {
      setConsolidatedOrderPlans(props.planDetailsPrevious);
      //clear now that plans have been reset
      props.setPlanDetailsPrevious([]);
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "/api/get_consolidated_order_plans_by_consolidated_order_header/" +
        props.consolidatedOrderHeader.id,
      requestOptions
    );
    let data = await response.json();
    if (!response.ok) {
      console.log("There is an error");
    } else {
      setConsolidatedOrderPlans(data);
    }
  };

  if (showSpinner || !consolidatedOrders)
    return (
      <>
        <div className="d-flex flex-column min-vh-100 min-vw-100">
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );

  return (
    <div>
      <Header
        title={"LYS VAN BESTELLINGS"}
        backPath={"orderListView"}
        setPath={props.setPath}
      />
      <div className="row">
        <div className="col-lg-12">
          <label
            style={{ marginTop: "1%", marginLeft: "2%", outline: "none" }}
            className="p-button-text consolidated_header_color"
          >
            <h1>Gekonsolideerde bestelling</h1>
            <p>{props.email}</p>
          </label>
          <hr />
        </div>
      </div>
      <div className="row">
        <h2
          style={{ marginLeft: "2%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Saamgevoegde bestelling
        </h2>
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <h4>Agent (Gewasadviseur)</h4>
            </div>
            <div className="col-lg-6">
              <h4>Syngenta Verkoopsbestuurder</h4>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label id="agent">Naam: {orderHeaderAgentDetail?.name}</label>
            </div>
            <div className="col-lg-6">
              <label id="parentName">Naam: {props.parentDetail?.name}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Handelaar: {props.agentDetail?.merchantRel?.name}</label>
            </div>
            <div className="col-lg-6">
              <label>Epos: {props.parentDetail?.email}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Depot: {orderHeaderAgentDetail?.depot}</label>
            </div>
            <div className="col-lg-6">
              <label id="parentContactNo">
                Kontak nommer: {props.parentDetail?.contactNo}
              </label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Epos: {orderHeaderAgentDetail?.email}</label>
            </div>
            <div className="col-lg-6">
              <p></p>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Kontak nommer: {orderHeaderAgentDetail?.contactNo}</label>
            </div>
            <div className="col-lg-6">
              <label>
                Bestelling verwysing:{" "}
                {("0000000000" + props?.consolidatedOrderHeader?.id).slice(-7)}
              </label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <p></p>
            </div>
            <div className="col-lg-6">
              <label>
                Bestel datum:
                {" " +
                  new Date(props?.consolidatedOrderHeader?.dateCreated)
                    ?.toISOString()
                    .split("T")[0]}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Spuitprogramme geselekteer
        </h4>
        <p
          className="p mt-2 mb-1"
          style={{ marginLeft: "4%", outline: "none" }}
        >
          Neem kennis: Addisionele korting word aan bestellings kleiner as
          R2,000,000 toegeken indien die gesamentlike bestelling R2,000,000
          oorskry.
        </p>

        <table className="table w-75" style={{ marginLeft: "4%" }}>
          <thead>
            <tr style={{ color: "#FFFFFF", backgroundColor: "#00a352" }}>
              <th scope="col" style={{ width: "10%" }}>
                Spuitprogram verwysing
              </th>
              <th scope="col">Gewas</th>
              <th scope="col">Klient</th>
              <th scope="col">Plaas</th>
              <th scope="col">Kampe</th>
              <th scope="col">Aanplant datum</th>
              <th scope="col" className="text-end">
                Area (Ha)
              </th>
              <th scope="col" className="text-end text-nowrap">
                Lys Waarde
              </th>
              <th scope="col" className="text-end text-nowrap ">
                Afslag Waarde
              </th>
              <th scope="col" className="text-end text-nowrap">
                MyPack Waarde
              </th>
              <th scope="col" className="text-end">
                Spuitprogram en bestelling
              </th>
            </tr>
          </thead>
          <tbody>
            {consolidatedOrders.plans ? (
              consolidatedOrders.plans.map((item, index) => {
                return (
                  <tr
                    key={Math.random()}
                    className={`${
                      item.farmer_farm_name === "Totaal"
                        ? "showBorder"
                        : "noBorder"
                    }`}
                  >
                    <td>
                      {item.plan_id} {item.planName}
                    </td>
                    <td>{item.crop}</td>
                    <td>{item.farmer_business_name}</td>
                    <td>{item.farmer_farm_name}</td>
                    <td>{item.circles}</td>
                    <td>
                      {item.planting_date !== null
                        ? new Date(item.planting_date)
                            ?.toISOString()
                            .split("T")[0]
                        : ""}
                    </td>
                    <td className="text-end">{item.farmSize}</td>

                    <td className="text-end text-nowrap">
                      {item.plan_total_list_price
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>

                    <td className="text-end text-nowrap">
                      {item.plan_total_discount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>

                    <td className="text-end text-nowrap">
                      {item.plan_total_discount_price
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td>
                      <button
                        type="button"
                        className={`btn p-button p-component p-button-text consolidated_order_buttons  w-75 float-end ${
                          item.plan_id === null ? "d-none" : ""
                        }`}
                        onClick={() => {
                          //different names for same attribute - TD: to make all the same
                          item.planting_id =
                            item.plantingDetailId === undefined
                              ? item.planting_id
                              : item.plantingDetailId;
                          props.setBackPath("consolidatedOrderView");

                          //to reset the collection of planDetails when moving back to this view
                          props.setPlanDetailsPrevious(consolidatedOrderPlans);
                          let tempPlanDetails = [];
                          item.plantingDate = item.planting_date;
                          tempPlanDetails.push(item);
                          props.setPlanDetails([...tempPlanDetails]);
                          props.setPath("individualProgramView");
                          props.setFilterPlan(item.sprayProgramReference);
                          //required to calculate the discount given where total order values are > 2 milion
                          props.setOrderTotalListPrice(consolidatedOrders.plans.find(i=>i.farmer_farm_name === "Totaal").plan_total_list_price)
                        }}
                      >
                        Vertoon
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div></div>
            )}
          </tbody>
        </table>
      </div>

      <div>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Samevatting van die bestelling
        </h4>
        <p className="p mb-1" style={{ marginLeft: "4%", outline: "none" }}>
          Neem kennis: Pryse en waardes is onderhewig aan finale goedkeuring
          deur Syngenta.
        </p>

        <table className="table w-75" style={{ marginLeft: "4%" }}>
          <thead>
            <tr style={{ color: "#FFFFFF", backgroundColor: "#00a352" }}>
              <th scope="col">Produk</th>
              <th scope="col">AGI</th>
              <th scope="col" className="d-none">
                SKU kode
              </th>
              <th className="text-end" scope="col">
                Verpakking
              </th>
              <th className="text-end" scope="col">
                Bestel volume
              </th>
              <th className="text-end" scope="col">
                Aantal eenhede
              </th>
              <th className="text-end" scope="col">
                Volpak volume
              </th>
              <th className="text-end text-nowrap" scope="col">
                Lys waarde
              </th>
              <th className="text-end text-nowrap" scope="col">
                Afslag waarde
              </th>
              <th className="text-end text-nowrap" scope="col">
                MyPack waarde
              </th>
              <th className="text-end" scope="col">
                MyPack prys (R/L)
              </th>
            </tr>
          </thead>
          <tbody>
            {consolidatedOrders.product_summary ? (
              consolidatedOrders.product_summary.filter(item => item.isParent === 0).map((item) => {
                return (
                  <tr
                    key={Math.random()}
                    className={`${
                      item.AGI === null ? "showBorder" : "noBorder"
                    }`}
                  >
                    <td>
                      {item.product_name === "Totaal"
                        ? ""
                        : item.product_pack_name}
                    </td>
                    <td>{item.AGI}</td>
                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : item.full_quantity !== null &&
                          item.full_quantity !== 0
                        ? ((item.full_packs * item.full_pack_volume) /
                          item.full_quantity).toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : 0}{" "}
                      {item.unit !== null && item.unit.length > 1
                        ? item.unit
                        : ""}
                    </td>

                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : item.full_pack_units != null &&
                          item.full_pack_units !== 0
                        ? ((item.full_pack_volume / item.full_pack_units) *
                          item.quantity).toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : 0}{" "}
                      {item.unit !== null && item.unit.length > 1
                        ? item.unit
                        : ""}
                    </td>

                    <td className="text-end text-nowrap">
                      {item.AGI == null
                        ? ""
                        : (item.full_packs * item.full_pack_units).toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : (item.full_packs * item.full_pack_volume).toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                      {item.unit !== null && item.unit.length > 1
                        ? item.unit
                        : ""}
                    </td>
                    <td className="text-end text-nowrap">
                      {parseFloat(item.full_order_list_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end text-nowrap">
                      {parseFloat(item.full_order_discount)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end text-nowrap">
                      {parseFloat(item.full_order_final_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>

                    <td className="text-end">
                      {item.AGI == null ? "Korting: " : ""}
                      {(item.AGI == null
                        ? item.full_order_list_price !== null ||
                          item.full_order_list_price !== 0
                          ? (100 * item.full_order_discount) /
                            item.full_order_list_price
                          : 0
                        : parseFloat(
                            item.full_order_final_price / item.full_volume
                          )
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      {item.AGI == null ? "%" : ""}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div></div>
            )}
          </tbody>
        </table>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Indien en goedkeuring van die bestelling
        </h4>
        <p className=" w-75 p" style={{ marginLeft: "4%", outline: "none" }}>
          Met die indien van die bestelling, word die bestelling aan jou
          Syngenta verkoopsverteenwoordiger gestuur wat die bestelling hersien
          vir aanvanklike goedkeuring. Met goedkeuring word jou bestelling
          ingedien vir finale goedkeuring deur ‘n Syngenta Areabestuurder. Met
          finale goedkeuring sal jou Syngenta verkoopsverteenwoordiger jou met
          ‘n spesiale prysvorm voorsien om die bestelling deur te voer. Kontak
          gerus jou Syngenta verkoopsverteenwoordiger vir enige navrae of
          ondersteuning.
        </p>
      </div>
    </div>
  );
};

export default ConsolidatedOrderView;
