import React from 'react'
import ReactDOM from 'react-dom';

const MODAL_STYLES = {
    tranform: 'translate(-50%, -50%)',
    backgroundColor: 'rgb(228, 238, 228)',
 }

export default function Modal({open, children, onClose, customStyle}) {
    if(!open) return null;
  return ReactDOM.createPortal (
    <>
    <div className='detail_font position-absolute top-50 start-50 translate-middle w-25 p-4 border border-3 rounded-4 zindex-modal'  style={MODAL_STYLES}>
        <button style={{border: 'none',borderRadius: 'none'}} className='btn-close float-end' onClick={onClose} aria-label="Close"/>
        {children}
    </div>
    </>,
    document.getElementById('portal')
  )
}
