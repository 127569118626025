import React, { useState, useEffect, useContext } from "react";
import Header from "../../../Header";
import SummaryTable from "../../PlanView/Components/SummaryTable";
import { ProgressSpinner } from "primereact/progressspinner";
import OrderVolumesValues from "../../PlanView/Components/OrderVolumesValues"

const IndividualProgramView = (props) => {
  const [token] = useState(localStorage.getItem("agentToken"));
  const [backPath] = useState(props.backPath);
  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [choices, setChoices] = useState([]);
  const [, setProceedBool] = useState(true);
  const [agentDetail] = useState(props.agentDetail);
  const [currentDate, setCurrentDate] = useState("");
  const [displayProducts, setDisplayProducts] = useState([]);
  const [weeks] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    getCategories();
    getProductTypes();
    getAllChoices();
    
    const current = new Date();
    let currentDate = `${current.getFullYear()}-${
      current.getMonth() + 1
    }-${current.getDate()}`;

    if (
      props.consolidatedOrderHeader != null &&
      props.consolidatedOrderHeader.dateCreated != null
    ) {
      currentDate = new Date(props.consolidatedOrderHeader.dateCreated)
        ?.toISOString()
        .split("T")[0];
    }
    setCurrentDate(currentDate);
  }, []);

  useEffect(() => {
    if(choices.length === 0)
      return;
    if(productTypes === undefined||null)
      return;  
    if(productTypes.length === 0)
      return;  
    sortProducts();
  }, [choices.length,productTypes, categories]);

  const getCategories = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/get_all_categories/${props.cropId}`, requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setCategories([...data]);
    }
  };

  const getProductTypes = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(`/api/get_all_products_types/${props.cropId}`, requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setProductTypes([...data]);
    }
  };

  const getAllChoices = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/all_choices/", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setChoices([...data]);
    }
  };

  const getPlanProducts = async () => {
    if(props.planDetails === null  || props.planDetails.length===0)
      return [] 
    const token = localStorage.getItem("agentToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/get_plan_products_by_plan_id/" + props.planDetails[0].plan_id,
      requestOptions
    );
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      const data = await response.json();
    return data;
    }
  };
 
  //remove comments at a later stage
  const sortProducts = async () => {

    //get all plan products for a specific plan id
    const planProducts = await getPlanProducts();
    var summary = {};

    //loop through all the products for a specific plan
    for (const p of planProducts) {

      //get details of plan product choice
      const choice = props.choices.find((choice) => choice.id === p.choice_id);

      //get the category from the choice - i.e. Insekdoders, Onkruidoders
      const category = categories.find(
        (category) => category.id === choice.category_id
      );

      
      //per category - Grasse (vooropkoms)
      const productType = productTypes.find(
        (pt) => pt.id === choice.productType_id
      );
      
      //so far, this is all the info for a choice
      
      //create a new summary[category.name] i.e. Insekdoders
      summary[category.name] = summary[category.name] || {};
      
      //create a unique category, producttype record as it loops through the plan products
      summary[category.name][productType.name] =
        summary[category.name][productType.name] || {};

      //create a unique category, producttype, product record as it loops through the plan products  
      summary[category.name][productType.name][p.product_name] =
        summary[category.name][productType.name][p.product_name] || {};

      const weeks = JSON.parse(p.weeks).map((w)=>w.val)   
      //for every category, producttype, product, assign a dosage  
      summary[category.name][productType.name][p.product_name]["weeks_dosage"] =
      summary[category.name][productType.name][p.product_name][
        "weeks_dosage"
      ] || Array(weeks.length).fill(0);

      
      const weekLabels = JSON.parse(p.weeks).map((w)=>w.desc) 
      const weeks_dosage0 =
        summary[category.name][productType.name][p.product_name]["weeks_dosage"];

      //get the total dosage for all the weeks  
      const weeks_dosage = weeks.map((w) => w * p.dosage);

      //assign the total dosage for category, producttype, product combination
      summary[category.name][productType.name][p.product_name]["weeks_dosage"] =
        weeks_dosage0.map((w, i) => w + weeks_dosage[i]);
      summary[category.name][productType.name][p.product_name]["weeks"] = summary[category.name][productType.name][p.product_name]["weeks_dosage"].map((d) =>
        d === 0 ? "" : `${d.toFixed(2)} Lt/Ha`
      );

      //assign the week headers to each category, producttype, product combination - they are all the same per crop
      summary[category.name][productType.name][p.product_name]["weekLabels"] = ["Teiken","Product",...weekLabels]
 
    }
    //end of plan products loop -> newx plan product that creates it summary record per product
 
    var sorted_summary = {};
    for (const category of categories) {
      if (category.name in summary)
        sorted_summary[category.name] = summary[category.name];
    }
    setDisplayProducts(sorted_summary);
    if (Object.keys(summary).length === 0) {
      setProceedBool(true);
    } else {
      setProceedBool(false);
    }
  };

  if (!choices || choices.length === 0)
    return (
      <>
        <div className="d-flex flex-column min-vh-100 min-vw-100">
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );

  return (
    <div style={{ width: "100%" }}>
      <Header
        title={"Gekonsolideerde bestelling".toUpperCase()}
        backPath={backPath}
        setPath={props.setPath}
      />

      <div className="row">
        <div className="col-lg-8">
          <div
            className="row mt-3"
            style={{ marginLeft: "5%", outline: "none" }}
          >
            <div className="col-lg-4">
              <h4>Klient naam: {props.planDetails !== null  && props.planDetails.length>0?props.planDetails[0].planName:""}</h4>
            </div>
            <div className="col-lg-4">
              <h4>Agent (Gewasadviseur)</h4>
            </div>
            <div className="col-lg-4">
              <h4>Syngenta Verkoopsbestuurder</h4>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-4">
              <label id="farmer">
                Plaas naam: {props.planDetails !== null  && props.planDetails.length>0 ? props.planDetails[0].farmer_farm_name:""}
              </label>
            </div>
            <div className="col-lg-4">
              <label id="agent">Naam: {agentDetail?.name}</label>
            </div>
            <div className="col-lg-4">
              <label id="parentName">Naam: {props.parentDetail?.name}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-4">
              <label>
                Kontak persoon: {props.planDetails !== null  && props.planDetails.length>0?props.planDetails[0].farmer_contact_person:""}
              </label>
            </div>
            <div className="col-lg-4">
              <label>Handelaar: {props.agentDetail?.merchantRel?.name}</label>
            </div>
            <div className="col-lg-4">
              <label>Epos: {props.parentDetail?.email}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-4">
              <label>Epos: {props.planDetails !== null  && props.planDetails.length>0 ? props.planDetails[0].farmer_email:""}</label>
            </div>
            <div className="col-lg-4">
              <label>Depot: {agentDetail?.depot}</label>
            </div>
            <div className="col-lg-4">
              <label id="parentContactNo">
                Kontak nommer: {props.parentDetail?.contactNo}
              </label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-4">
              <label>
                Kontak nommer: {props.planDetails !== null  && props.planDetails.length>0 ? props.planDetails[0].farmer_contact_number: ""}
              </label>
            </div>
            <div className="col-lg-4">
              <label>Epos: {agentDetail?.email}</label>
            </div>
            <div className="col-lg-4">
              <p></p>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <label>Kontak nommer: {agentDetail?.contactNo}</label>
            </div>
            <div className="col-lg-4">
              <label>
                Bestelling verwysing:{" "}
                {props?.consolidatedOrderHeader === null
                  ? ""
                  : ("0000000000" + props?.consolidatedOrderHeader?.id).slice(
                      -7
                    )}
              </label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-4">
              <p></p>
            </div>
            <div className="col-lg-4">
              <p></p>
            </div>
            <div className="col-lg-4">
              <label
                className={`${!props.consolidatedOrderHeader ? "" : "d-none"}`}
              >
                Bestel datum: {currentDate}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Spuitprogram besonderhede
        </h4>

        <table className="table w-75" style={{ marginLeft: "4%" }}>
          <thead>
            <tr style={{ color: "#FFFFFF", backgroundColor: "#00a352" }}>
              <th scope="col" style={{ width: "10%" }}>
                Spuitprogram verwysing
              </th>
              <th scope="col">Gewas</th>
              <th scope="col">Kampe</th>
              <th scope="col">Aanplant datum</th>
              <th scope="col">Area (Ha)</th>
            </tr>
          </thead>
          <tbody>
            <tr className="showBorder">
              <td>{props.planDetails !== null  && props.planDetails.length>0 ? props.planDetails[0].plan_id + " "+props.planDetails[0].planName: -1}</td>
              <td>{props.planDetails!== null && props.planDetails.length>0 ? props.planDetails[0].crop : ""}</td>
              <td>{props.planDetails!== null && props.planDetails.length>0 ? props.planDetails[0].circles : ""}</td>
              <td>
                {props.planDetails !== null  && props.planDetails.length>0 && props.planDetails[0].plantingDate !== null
                ?new Date(props.planDetails[0].plantingDate).toISOString().split("T")[0]
                :""}
              </td>
              <td>{props.planDetails !== null  && props.planDetails.length>0 ? props.planDetails[0].farmSize:0}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <OrderVolumesValues
        planDetails={props.planDetails !== null  && props.planDetails.length>0?
        { id: props.planDetails[0].plan_id }:{id: -1 }}
        orderTotalListPrice = {props.orderTotalListPrice}  
      >
  </OrderVolumesValues>
  <SummaryTable
    displayProducts={displayProducts}
    productChange={false}
    setProceedBool={setProceedBool}
    productTypes={productTypes}
    weeks={weeks}
    categories={categories}
    planDetails={props.planDetails !== null  && props.planDetails.length>0?
      { id: props.planDetails[0].plan_id }:{id: -1 }}
    products={props.products}
    choices={choices}
  />
    </div>


  );
};
export default IndividualProgramView;
