import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { locale, addLocale } from "primereact/api";
import ProgramDuplication from "./ProgramDuplication";

const DuplicatePlanView = (props) => {
  const [token, setToken] = useState(localStorage.getItem("agentToken"));
  const [plantingDetail, setPlantingDetail] = useState(null);
  const childRef = useRef(null);
  const [planName, setPlanName] = useState(props?.oldPlan?.planName);
  const [plantingDate, setPlantingDate] = useState(
    new Date(props?.oldPlan?.plantingDate)
  );
  const [plantingDateInt, setPlantingDateInt] = useState(
    props?.oldPlan?.plantingDate
  );
  const [newPlanDetails, setNewPlanDetails] = useState(null);

  useEffect(() => {
    if (plantingDetail !== null) {
      CreateNewPlan();
    }
  }, [plantingDetail]);

  const handleClick = async () => {
    await childRef.current.childFunction1();
  };
  addLocale("af", {
    firstDayOfWeek: 0,
    dayNames: [
      "Sondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrydag",
      "Saterdag",
    ],
    dayNamesShort: ["Son", "Maan", "Din", "Woen", "Don", "Vry", "Sat"],
    dayNamesMin: ["S", "M", "D", "W", "D", "V", "S"],
    monthNames: [
      "Januarie",
      "Februarie",
      "Maart",
      "April",
      "Mei",
      "Junie",
      "Julie",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    dateFormat: "dd/mm/yyy",
    today: "Vandag",
    clear: "Vee uit",
  });
  locale("af");

  const CreateNewPlan = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        planName: planName,
        planting_id: plantingDetail.id,
        plantingDate: plantingDateInt,
        archived: false,
        orderStatus_id: 1,
        crop_id: props.cropId 
      }),
    };

    const response = await fetch("/api/create_plan", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error creating the plan')
    } else {
      setNewPlanDetails(data);
      props.setPlanDetails(data);
    }
  };

  useEffect(() => {
    if (newPlanDetails !== null) {
      duplicatePlan();
    }
  }, [newPlanDetails]);

  const duplicatePlan = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        planName: planName,
        planting_id: plantingDetail.id,
        plantingDate: plantingDateInt,
      }),
    };

    const response = await fetch(
      "/api/duplicate_plan/" + props.oldPlan.id + "/" + newPlanDetails.id,
      requestOptions
    );
    
    if (!response.ok) {
      //console.log('There is an error creating the plan')
    } else {
      props.setDupPlanBool(false);
    }
  };

  useEffect(() => {
    //console.log(props)
  }, []);
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: "2%",
    alignItems: "center",
    justifyContent: "center",
  };
  const CardStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "98%",
    margin: "1%",
  };
  const ContainerCardStyle = {
    display: "flex",
    flexDirection: "column",
    width: "50vw",
  };
  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Card style={CardStyle}>
        <div style={ContainerCardStyle}>
          <div style={containerStyle}>
            <span style={{ width: "100%", marginBottom: "2%" }}>
              <label style={{ marginBottom: "2%" }}>Program Naam*</label>
              <span className="p-float-label" style={{ width: "100%" }}>
                <InputText
                  style={{ width: "100%" }}
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                />
              </span>
            </span>
          </div>

          <div style={containerStyle}>
            <ProgramDuplication
              ref={childRef}
              style={{ width: "100%" }}
              setPlantingDetail={setPlantingDetail}
              oldPlantingDetail={props.oldPlantingDetail}
              crops={props.crops}
              cropId={props.cropId}
            />
          </div>
          <>
            <div style={containerStyle}>
              <span
                style={{ width: "100%", marginBottom: "2%", marginTop: "2%" }}
              >
                <label style={{ marginBottom: "2%" }}>Plant datum*</label>
                <span className="p-float-label" style={{ width: "100%" }}>
                  <Calendar
                    id="icon"
                    value={plantingDate}
                    dateFormat="dd/mm/yy"
                    locale="af"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setPlantingDateInt(e.value.valueOf());
                      setPlantingDate(e.value.toLocaleDateString());
                    }}
                    showButtonBar
                    showIcon
                  ></Calendar>
                </span>
              </span>
            </div>
          </>
        </div>
        {plantingDate !== "" && planName !== "" ? (
          <>
            <Button
              label="Skep Nuwe Plan"
              style={{
                backgroundColor: "#00a352",
                borderColor: "#00a352",
                width: "100%",
              }}
              onClick={handleClick}
            />
          </>
        ) : (
          <>
            <Button
              label="Skep Nuwe Plan"
              style={{
                backgroundColor: "#00a352",
                borderColor: "#00a352",
                width: "100%",
              }}
              onClick={handleClick}
              disabled={true}
            />
          </>
        )}
      </Card>
    </div>
  );
};

export default DuplicatePlanView;
