import React, { Component, useState, useEffect } from "react";
import Slider from "rc-slider";
import "./CheckBoxRow.css";
import "rc-slider/assets/index.css";
import { ProgressSpinner } from "primereact/progressspinner";
import SummaryAccordianPrint from "./SummaryAccordPrint";
import ReactToPrint from "react-to-print";

const SummaryTablePrint = React.forwardRef((props, ref) => {
  const [token,] = useState(localStorage.getItem("agentToken"));
  const [,setProductTypes] = useState([]);
  const [,setCategories] = useState([]);
  const [,setPlanProducts] = useState([]);
  const choices = props.choices;
  const products = props.products;
  const [displayProducts, setDisplayProducts] = useState([]);
  const [,setTableProducts] = useState([]);

  const getPlanProducts = async (productTypes, categories) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/get_plan_products_by_plan_id/" + props.planDetails.id,
      requestOptions
    );

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setPlanProducts([data]);
      sortProducts(categories, productTypes, data);
    }
  };

  const getProductTypes = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(`/api/get_all_products_types/${props.cropId}`, requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setProductTypes([...data]);
      getCategories(data);
    }
  };

  const sortProducts = (categories, productTypes, planProducts) => {
    var res2 = [];
    let obj = {};
    for (var index in planProducts) {
      var planProduct = planProducts[index];
      var choice = choices.filter(
        (choice) => choice.id === planProduct.choice_id
      )[0];
      var category = categories.filter(
        (category) => category.id === choice.category_id
      )[0];
      var productType = productTypes.filter(
        (pt) => pt.id === choice.productType_id
      )[0];
      var product = products.filter(
        (product) => product.id === choice.product_id
      )[0];

      var weeksArr = planProduct.weeks.split(",");

      var disWeekArr = [];
      for (var index in weeksArr) {
        var week = weeksArr[index];
        if (week === "true") {
          disWeekArr.push(planProduct.dosage + " " + product.unit + "/Ha");
        } else {
          disWeekArr.push("");
        }
      }

      if (!obj[category.name]) {
        obj[category.name] = {};
      }
      if (!obj[category.name][productType.name]) {
        obj[category.name][productType.name] = {};
      }
      obj[category.name][productType.name][product.name] = {};
      obj[category.name][productType.name][product.name]["weeks"] = disWeekArr;
    }

    var keys = Object.keys(obj);
    for (var keyIndex in keys) {
      var key = keys[keyIndex];
      let tmObj = {};
      let el = obj[key];
      tmObj[key] = el;

      res2.push(tmObj);
    }
    setDisplayProducts([...res2]);
  };

  useEffect(() => {
    getProductTypes();
  }, []);

  useEffect(() => {
    getProductTypes();
  }, [props.productChange]);
  useEffect(() => {
    getProductTypes();
  }, []);

  const getCategories = async (productTypes) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/get_all_categories/${props.cropId}`, requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setCategories([...data]);
      getPlanProducts(productTypes, data);
    }
  };

  const downloadCSV = () => {
    //console.log(displayProducts)

    var disArr = [];
    for (var catIndex in displayProducts) {
      var catObj = displayProducts[catIndex];
      var category = Object.keys(catObj)[0];
      var csv = "";
      csv = csv + category + ",";
      var productTypeObjs = catObj[category];
      var productTypes = Object.keys(productTypeObjs);
      for (var PtIndex in productTypes) {
        var productType = productTypes[PtIndex];

        var productsObj = productTypeObjs[productType];
        var products = Object.keys(productsObj);
        for (var productIndex in products) {
          csv = csv + productType + ",";
          var product = products[productIndex];
          var productWeeks = productsObj[product]["weeks"];
          for (var weekIndex in productWeeks) {
            var weekString = productWeeks[weekIndex];
            if (weekString !== "") {
              csv = csv + product + " " + weekString + ",";
            } else {
              csv = csv + ",";
            }
          }
          disArr.push(csv);

          csv = category + ",";
        }
      }
      setTableProducts([...disArr]);
    }
  };

  const dCSV = (csv) => {
    let csvFile = new Blob([csv], { type: "text/csv" });
    let downloadLink = document.createElement("a");
    downloadLink.download = "eee.csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <div
      className="col-12"
      style={{ marginTop: "1%", display: "block" }}
      ref={ref}
    >
      {displayProducts.length < 1 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {displayProducts.map((item, index) => {
            var categoryName = Object.keys(item)[0];
            return (
              <div
                key={Math.random()}
                style={{ height: "100vh", margin: "0%" }}
              >
                <SummaryAccordianPrint
                  category_name={categoryName}
                  object={item[categoryName]}
                />
                <></>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
});

export default SummaryTablePrint;
