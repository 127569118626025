import React, { useState, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import "./OrderProductCard.css";

const OrderProductCard = (props) => {
  const [orderVolume, setOrderVolume] = useState(props.orderVolume);
  const [planVolume] = useState(props.planVolume);
  const [qty20, setQty20] = useState(0);
  const [qty5, setQty5] = useState(0);
  const [qty1, setQty1] = useState(0);
  const [token] = useState(localStorage.getItem("agentToken"));
  

  const setQtys = () => {
    for (var keyIndex in Object.keys(props.packages)) {
      let pkSize = Object.keys(props.packages)[keyIndex];
      let pkQty = props.packages[pkSize];
      if (pkSize === "20") {
        setQty20(pkQty);
      }
      if (pkSize === "5") {
        setQty5(pkQty);
      }
      if (pkSize === "1") {
        setQty1(pkQty);
      }
    }
  };

  useEffect(() => {
    setQtys();
  }, []);

  useEffect(() => {
    setOrderVolume(props.orderVolume);
  }, [props.orderVolume]);


  const qtyChange20 = async (num) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/update_product_packages/${props.id}/20/${num}`, requestOptions);

    if (!response.ok) {
    } else {
      setQty20(num);
      props.getOrderProducts();
    }
  };

  const qtyChange5 = async (num) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/update_product_packages/${props.id}/5/${num}`, requestOptions);
    await response.json();

    if (!response.ok) {
      //console.log('There is an error creating the order product')
    } else {
      setQty5(num);
      props.getOrderProducts();
    }
  };

  const qtyChange1 = async (num) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/update_product_packages/${props.id}/1/${num}`, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      console.log('There is an error creating the order product')
    } else {
      setQty1(num);
      console.log(data);
      props.getOrderProducts();
    }
  };

  const topDivStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <td className="text-center pt-5">{props.name}</td>
      <td className="text-center pt-5">
        {planVolume
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
      </td>
      <td className="text-center pt-5">{orderVolume.toFixed(0).toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>
      <td className="text-center pt-5">
        <h5>
          <span className={`difference_in_volume_color ${orderVolume - planVolume > 0 ? "" : "d-none"}`}>+</span>
          {(orderVolume - planVolume)
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        </h5>
      </td>

      <td style={{ textAlign: "center" }}>
        <div key={"Packaging Labels"} className="packagingLabels d-inline">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                {/*20L*/}
                {props.qtys.split(",").indexOf("20") !== -1 ? (
                  <div style={topDivStyle}>
                    <h5 className="m-0 p-0"> 20 {props.unit}</h5>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        icon="pi pi-minus"
                        className="p-button-success"
                        onClick={() => qtyChange20(qty20 - 1)}
                        style={{ borderRadius: "5px 0px 0px 5px" }}
                        disabled={props?.isParentProduct ? false : props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}

                      />
                      <InputNumber
                        value={props.packages["20"]}
                        size={1}
                        onValueChange={(e) => qtyChange20(e.value)}
                        inputStyle={{ borderRadius: "0px", textAlign: "center" }}
                        min={0}
                        disabled={props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}
                      />
                      <Button
                        icon="pi pi-plus"
                        className="p-button-success"
                        onClick={() => {
                          qtyChange20(qty20 + 1)}}
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                        disabled={props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={topDivStyle}>
                    <h5 style={{ margin: "0%", padding: "0%" }}> 20 {props.unit}</h5>
                    <InputNumber
                      value={0}
                      disabled={true}
                      size={1}
                      inputStyle={{ textAlign: "center" }}
                      showButtons
                      buttonLayout="horizontal"
                      decrementButtonClassName="p-button-success"
                      incrementButtonClassName="p-button-success"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      min={0}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                {/*5L*/}
                {props.qtys.split(",").indexOf("5") !== -1 ? (
                  <div style={topDivStyle}>
                    <h5 className="m-0 p-0"> 5 {props.unit}</h5>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        icon="pi pi-minus"
                        className="p-button-success"
                        onClick={() => qtyChange5(qty5 - 1)}
                        style={{ borderRadius: "5px 0px 0px 5px" }}
                        disabled={props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}
                      />
                      <InputNumber
                        value={props.packages["5"]}
                        size={1}
                        onValueChange={(e) => qtyChange5(e.value)}
                        inputStyle={{ borderRadius: "0px", textAlign: "center" }}
                        min={0}
                        disabled={props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}
                      />
                      <Button
                        icon="pi pi-plus"
                        className="p-button-success"
                        onClick={() => qtyChange5(qty5 + 1)}
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                        disabled={props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={topDivStyle}>
                    <h5 style={{ margin: "0%", padding: "0%" }}> 5 {props.unit}</h5>
                    <InputNumber
                      disabled={true}
                      value={0}
                      size={1}
                      inputStyle={{ textAlign: "center" }}
                      showButtons
                      buttonLayout="horizontal"
                      decrementButtonClassName="p-button-success"
                      incrementButtonClassName="p-button-success"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      min={0}
                    />
                  </div>
                )}
              </div>


              <div className="col-lg-4">
                {/* 1Lt */}
                {props.qtys.split(",").indexOf("1") !== -1 ? (
                  <div style={topDivStyle}>
                    <h5 className="m-0 p-0"> 1 {props.unit}</h5>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Button
                        icon="pi pi-minus"
                        className="p-button-success"
                        onClick={() => qtyChange1(qty1 - 1)}
                        style={{ borderRadius: "5px 0px 0px 5px" }}
                        disabled={props?.isParentProduct ? true : props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}

                      />
                          
                      <InputNumber
                        value={props.packages["1"]}
                        size={1}
                        onValueChange={(e) => qtyChange1(e.value)}
                        inputStyle={{ borderRadius: "0px", textAlign: "center" }}
                        min={0}
                        disabled={props?.isParentProduct ? true : props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}
                      />
                   
                      <Button
                        icon="pi pi-plus"
                        className="p-button-success"
                        onClick={() => qtyChange1(qty1 + 1)}
                        style={{ borderRadius: "0px 5px 5px 0px" }}
                        disabled={props?.isParentProduct ? true : props?.planDetails?.orderStatus_id !== null 
                          ? props.planDetails.orderStatus_id > 1 
                          : false}

                      />
                    </div>
                  </div>
                ) : (
                  <div style={topDivStyle}>
                    <h5 style={{ margin: "0%", padding: "0%" }}> 1 {props.unit}</h5>
                    <InputNumber
                      value={0}
                      disabled={true}
                      size={1}
                      inputStyle={{ textAlign: "center" }}
                      showButtons
                      buttonLayout="horizontal"
                      decrementButtonClassName="p-button-success"
                      incrementButtonClassName="p-button-success"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      min={0}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </td>
    </>
  );
};

export default OrderProductCard;
