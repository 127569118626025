export const ConsolidatedOrderApi = {
  createCommercialTermMypackOrder: async function (consolidatedOrderId) {
    return await fetch(
      "/api/create_commercial_term_mypack_order/" + consolidatedOrderId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("agentToken"),
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log(
            "Creation of commercial term mypack order was successful"
          );
          return response.text();
        }
        else{
          console.log(
            "Creation of commercial term mypack order was not successful"
          );
          return response.text()
        }
      })
      .catch((error) => {
        console.error(
          "There was an error in ConsolidatedOrderApi.createCommercialTermMypackOrder",
          error
        );
        return error.message;
      });
  },

  //keep for now. While doing development, use this one 
  createCommercialTermMypackOrder_dummy_blah: async function (consolidatedOrderId) {
    return await fetch(
      "/api/create_commercial_term_mypack_order_dummy_blah/" + consolidatedOrderId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("agentToken"),
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log(
            "Creation of commercial term mypack order was successful"
          );
          return response.text();
        }
        else{
          console.log(
            "Creation of commercial term mypack order was not successful"
          );
          return response.text()
        }
      })
      .catch((error) => {
        console.error(
          "There was an error in ConsolidatedOrderApi.createCommercialTermMypackOrder",
          error
        );
        return error.message;
      });
  }
};
export default ConsolidatedOrderApi;
