export const IngredientsAPI = {
  getPlanProductsPeriodIngredients: async function (productPlanIds) {
    const url = `/api/get_plan_products_period_ingredients`;
    return await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("agentToken"),
      },
      body: JSON.stringify({ productPlanIds })
    })
      .then((response) => {
        if (response.ok) return response.json();
      })
      .catch((error) => {
        console.error("There was an error in PlanProductPeriodIngredientsAPI.getAllCrops", error);
      });
  },

  getAllIngredients: async function(){
    const url = `/api/get_all_ingredients`;
      return await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("agentToken"),
        },
      })
        .then((response) => {
          if (response.ok) return response.json();
        })
        .catch((error) => {
          console.error("There was an error in IngredientsAPI.getAllIngredients", error);
        });
  }
}



export default IngredientsAPI;
