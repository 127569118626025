export const Token = localStorage.getItem("agentToken");

export const AgentAPI = {
  getAgentDetails: async (id) => {
      if (id == null) return;
      return await fetch("/api/get_agent/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Token,
        },
      }).then((response)=>{
        if (response.ok)
          return response.json();
      }).catch(()=>{
        console.log("There was an error in AgentAPI.getAgentDetails");
        return {}
      })
  },
 
  getRoles: async () => {
    try {
      const res = await fetch("/api/get_all_roles_by_current_agent", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Token,
        },
      });

      const result = await res.json();
      return result;
    } catch (e) {
      console.log("There was an error in AgentAPI.getRoles");
      return [];
    }
  },

  getRolesById: async (agent_id) => {
    try {
      const res = await fetch("/api/get_all_roles_by_agent_id/" + agent_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Token,
        },
      });

      const result = await res.json();
      return result;
    } catch (e) {
      console.log("There was an error in AgentAPI.getRoles");
      return [];
    }
  },
};

export default AgentAPI;
