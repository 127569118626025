import React, { useState } from "react";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Card } from "primereact/card";
import WeatherMapLogo from "../../../Images/LogoWeather.jpg";

const PricingTable = (props) => {
  const [token] = useState(localStorage.getItem("agentToken"));
  const [showSucessMessge] = useState(false);
  const [message] = useState("");

  const consolidateOrder = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    await fetch(
      "/api/create_completed_products/" + props.planDetails.id,
      requestOptions
    )
      .then((response) => {
        if (response.ok) return response.json();
      })
      .then(() => {
        props.setPath("pastOrders");
      });
  };

  return (
    <>
      <Card
        style={{ backgroundColor: "#F6F6F6", width: "100%", marginTop: "1%" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              width: 30 + "%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>
              {" "}
              <u>Lys Waarde</u>{" "}
            </h4>
            <h4>
              R
              {typeof props.orderPrice === "number"?parseFloat(props.orderPrice)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " "): "0.00"}
            </h4>
             <h5>
              R/Ha ={" "}
              {typeof props.orderPrice === "number"? (props.orderPrice / props.farmSize)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " "):"0.00"}
            </h5>
          </div>

          <div
            style={{
              width: 30 + "%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>
              {" "}
              <u>Afslag Waarde</u>{" "}
            </h4>
            <h4>
              R
              {props?.discountPrice
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
              (
              {typeof props?.orderPrice === "number"?((
                100 -
                ((props?.orderPrice - props?.discountPrice) /
                  props?.orderPrice) *
                  100
              )).toFixed(1):0}{" "}
              %)
            </h4>
            <h5>
              R/Ha ={" "}
              {typeof props?.orderPrice === "number"?((props.discountPrice / props.farmSize)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")):"0.00"}
            </h5>
          </div>

          <div
            style={{
              width: 30 + "%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>
              {" "}
              <u>MyPack Waarde</u>{" "}
            </h4>
            <h4>
              R
              {(props.orderPrice - props.discountPrice)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </h4>
            <h5>
              R/Ha ={" "}
              {typeof props?.orderPrice === "number"?(((props.orderPrice - props.discountPrice) / props.farmSize)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")):"0.00"}
            </h5>
          </div>
        </div>

        <div
          className={`row mt-5 ${
            props.isCustomerRegisteredForWeatherService ? "" : "d-none"
          }`}
        >
          <div className="col-lg-1"></div>
          <div className="col-lg-1"></div>
          <div className="col-lg-1">
            <img src={WeatherMapLogo} alt="" style={{ width: "40px" }} />
          </div>
          <div className="col-lg-9 text-start">
            <h5>
              Jy is geregistreerd vir die 'Weather and Risk Map' diens en
              kwalifiseer addisionele korting op die MyPack aankope. <br></br>
              <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">
                https://www.syngenta.co.za/service/weather-and-risk-map-service
              </a>
            </h5>
          </div>
        </div>
        <div
          className={`row mt-5 ${
            props.isCustomerRegisteredForWeatherService
              ? "d-none"
              : props.weatherDiscountLost > 0
              ? ""
              : "d-none"
          }`}
        >
          <div className="col-lg-1"></div>
          <div className="col-lg-1"></div>
          <div className="col-lg-1">
            <img src={WeatherMapLogo} alt="" style={{ width: "40px" }} />
          </div>
          <div className="col-lg-9 text-start">
            <h5>
              Teken gratis in vir Syngenta se 'Weather and Risk Map' diens om
              vir 'n addisionele korting van R
              {props.weatherDiscountLost.toFixed(2).toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} te kwalifiseer.<br></br>
              <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">
                https://www.syngenta.co.za/service/weather-and-risk-map-service
              </a>
            </h5>
          </div>
        </div>
      </Card>

      {showSucessMessge ? (
        <>
          <Message
            style={{ marginTop: "0%", width: "100%" }}
            severity="success"
            text={message}
          />
        </>
      ) : (
        <>
          <div>
            <div className="row">
              <div className="col text-center">
                <Button
                  className={`p-button-text order_buttons mt-3 w-25  h-75 ${
                    props?.planDetails?.orderStatus_id !== null
                      ? props.planDetails.orderStatus_id > 1
                        ? "d-none"
                        : ""
                      : ""
                  }
                  ${props?.justAView ? "d-none" : ""} `}
                  onClick={consolidateOrder}
                  style={{
                    display: "inline",
                  }}
                >
                  Stoor bestelling en gaan terug na tuisblad
                </Button>
                <Button
                  className={`p-button-text order_buttons mt-3 w-25  h-75 ${
                    props?.planDetails?.orderStatus_id !== null
                      ? props.planDetails.orderStatus_id === 1
                        ? "d-none"
                        : ""
                      : ""
                  }
                  ${props.justAView === true ? "d-none" : ""}
                  `}
                  onClick={() => props.setPath("pastOrders")}
                  style={{
                    display: "inline",
                  }}
                >
                  {props.justAView}
                  Gaan terug na tuisblad
                </Button>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col text-center">
                <label>
                  <strong>Neem kennis: </strong>
                  Bestellings word vanaf die welkom blad ingedien. Bestellings
                  kan individueel of gesamentlik ingedien word.
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PricingTable;
