import { Card } from "primereact/card";
import React, { useEffect, useState, useContext } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import axios from "axios";
import "./farmerReg.css";
import Header from "../../Header";

const FarmerReg = (props) => {
  const [token] = useState(localStorage.getItem("agentToken"));
  const [contactNumber, setContactNumber] = useState(props?.farm?.contactNumber);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [,setShowMessage] = useState(false);
  const [,setFormData] = useState({});
  const [phoneErrorMsg] = useState(false);
  const [backPath] = useState(props.backPath == null ? "pastOrders" : props.backPath);
  const [isNewFarmer] = useState(props.farm == null);

  props.setOpenConfirmationMessage(false);

  const formik = useFormik({
    initialValues: {
      businessName: props?.farm?.businessName,
      farmName: props?.farm?.farmName,
      nearestTown: props?.farm?.nearestTown,
      contactPerson: props?.farm?.contactPerson,
      contactNumber: props?.farm?.contactNumber,
      email: props?.farm?.email,
    },
    validate: (data) => {
      let errors = {};
      data.contactNumber = contactNumber;
      setFormData(data);
      if (!data.businessName) {
        errors.businessName = "Boerdery vereis.";
      }
      if (!data.farmName) {
        errors.farmName = "Plaasnaam vereis.";
      }
      if (!data.nearestTown) {
        errors.nearestTown = "Naaste Dorp vereis.";
      }
      if (!data.contactPerson) {
        errors.contactPerson = "Kontakpersoon vereis.";
      }
      if (!data.contactNumber) {
        errors.contactNumber = "Telefoonnommer vereis.";
      }

      if (data.contactNumber?.length < 10) {
        errors.contactNumber = "Ongeldige telefoonnommer";
      }

      if (!data.email) {
        errors.email = "Kontakpersoon epos vereis.";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = "Ongeldige eposadres. E.g. example@email.com";
      }

      return errors;
    },
    onSubmit: (data) => {
      data.contactNumber = contactNumber;
      setFormData(data);
      setShowMessage(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const farmer = {
        businessName: data.businessName,
        farmName: data.farmName,
        nearestTown: data.nearestTown,
        contactPerson: data.contactPerson,
        contactNumber: data.contactNumber,
        email: data.email,
      };

      if (isNewFarmer) {
        axios.post(`/api/create_farmer`, farmer, config).then((res) => {
          if (res.statusText === "OK") {
            props.setFarm(null);
            setTimeout(() => {
              setShowSpinner(false);
              setShowSuccessMessage(true);
              setTimeout(() => {
                props.setPath(backPath);
              }, 2000);
            }, 1000);
          }
        });
      }

      if (!isNewFarmer) {
        axios.put(`/api/farmers/` + props.farm.id, farmer, config).then((res) => {
          if (res.statusText === "OK") {
            setTimeout(() => {
              setShowSpinner(false);
              setShowSuccessMessage(true);
              setTimeout(() => {
                props.setPath(backPath);
              }, 2000);
            }, 1000);
          }
        });
      }
    },
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Header title={"SKEP KLIENT"} backPath={backPath} setPath={props.setPath} />
      <div style={{ width: "auto" }}>
        <Card style={{ width: "auto" }}>
          <span
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <span style={{ marginBottom: "2%" }}>
                <label
                  htmlFor="Business Name"
                  className={classNames({
                    "p-error": isFormFieldValid("businessName"),
                  })}
                >
                  Boerdery*
                </label>
                <span className="p-float-label">
                  <InputText
                    id="Business Name"
                    style={{ width: "35vw" }}
                    name="businessName"
                    value={formik.values.businessName}
                    onChange={formik.handleChange}
                    autoFocus
                    className={classNames({
                      "p-invalid": isFormFieldValid("businessName"),
                    })}
                  />
                </span>
                {getFormErrorMessage("businessName")}
              </span>
              <span style={{ marginBottom: "2%" }}>
                <label
                  htmlFor="Farm Name"
                  className={classNames({
                    "p-error": isFormFieldValid("farmName"),
                  })}
                >
                  Plaasnaam*
                </label>
                <span className="p-float-label">
                  <InputText
                    id="Farm Name"
                    style={{ width: "35vw" }}
                    name="farmName"
                    value={formik.values.farmName}
                    onChange={formik.handleChange}
                    autoFocus
                    className={classNames({
                      "p-invalid": isFormFieldValid("farmName"),
                    })}
                  />
                </span>
                {getFormErrorMessage("farmName")}
              </span>

              <span style={{ marginBottom: "2%" }}>
                <label
                  htmlFor="Nearest Town"
                  className={classNames({
                    "p-error": isFormFieldValid("nearestTown"),
                  })}
                >
                  Naaste Dorp*
                </label>
                <span className="p-float-label">
                  <InputText
                    id="Nearest Town"
                    style={{ width: "35vw" }}
                    name="nearestTown"
                    value={formik.values.nearestTown}
                    onChange={formik.handleChange}
                    autoFocus
                    className={classNames({
                      "p-invalid": isFormFieldValid("nearestTown"),
                    })}
                  />
                </span>
                {getFormErrorMessage("nearestTown")}
              </span>

              <span style={{ marginBottom: "2%" }}>
                <label
                  htmlFor="Contact Person"
                  className={classNames({
                    "p-error": isFormFieldValid("contactPerson"),
                  })}
                >
                  Kontakpersoon*
                </label>
                <span className="p-float-label">
                  <InputText
                    id="Contact Person"
                    style={{ width: "35vw" }}
                    name="contactPerson"
                    value={formik.values.contactPerson}
                    onChange={formik.handleChange}
                    autoFocus
                    className={classNames({
                      "p-invalid": isFormFieldValid("contactPerson"),
                    })}
                  />
                </span>
                {getFormErrorMessage("contactPerson")}
              </span>

              <span style={{ marginBottom: "2%" }}>
                <label style={{ marginBottom: "2%" }}>Kontakpersoon epos*</label>
                <span className="p-float-label">
                  <InputText
                    id="Email"
                    style={{ width: "35vw" }}
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoFocus
                    className={classNames({
                      "p-invalid": isFormFieldValid("email"),
                    })}
                  />
                </span>
                {getFormErrorMessage("email")}
              </span>

              <span style={{ marginBottom: "2%" }}>
                <label>Kontaknommer*</label>
                <span className="p-float-label">
                  <PhoneInput
                    inputStyle={{
                      width: "35vw",
                      marginTop: "2%",
                      height: "3vw",
                    }}
                    preferredCountries={["za"]}
                    country={"za"}
                    masks={{ za: "(..) ...-....", at: "(....) ...-...." }}
                    inputProps={{
                      id: "contactNumber",
                      name: "contactNumber",
                      autoFocus: true,
                    }}
                    value={formik.values.contactNumber}
                    onChange={(e) => {
                      setContactNumber(e);
                      formik.handleChange(e);
                    }}
                    inputClass={classNames({
                      "p-invalid": isFormFieldValid("contactNumber"),
                    })}
                  />
                </span>
                <>
                  {getFormErrorMessage("contactNumber")}
                  {(phoneErrorMsg === true) & (contactNumber?.length < 11) ? (
                    <small className={classNames({ "p-error": true })}>Ongeldige telefoonnommer</small>
                  ) : (
                    <></>
                  )}
                </>
              </span>

              {showSpinner ? (
                <ProgressSpinner />
              ) : (
                <>
                  {showSuccessMessage ? (
                    <Message
                      style={{ marginTop: "2%", width: "100%" }}
                      severity="success"
                      text={isNewFarmer ? "Klient is suksesvol geskep" : "Klient is suksesvol opgedateer"}
                    />
                  ) : (
                    <>
                      <Button
                        style={{
                          marginTop: "2vh",
                          backgroundColor: "#00a352",
                          borderColor: "#00a352",
                        }}
                        label="SKEP KLIENT"
                      />
                    </>
                  )}
                  <button
                    onClick={() => {
                      props.setPath("growerListView");
                    }}
                  ></button>
                </>
              )}
            </form>
          </span>
        </Card>
      </div>
    </div>
  );
};

export default FarmerReg;
