import React, { useState } from "react";
import "./Accordian.css";
import { useEffect } from "react";

// import PlanProducts from './PlanProducts';
import ProductTypeAccordianPrint from "./SummaryProdTypeAccordPrint";
const SummaryAccordianPrint = (props, ref) => {
  const [isActive, setIsActive] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  const [weeks,] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
 
  const colorCode = {
    ONKRUIDDODERS: "#FFFFFF",
    "SAADMOER BESKERMING": "#FFFFFF",
    SWAMDODERS: "#FFFFFF",
    INSEKDODERS: "#FFFFFF",
    ANDER: "#FFFFFF",
  };

  useEffect(() => {
    let keys = Object.keys(props.object);
    let res = [];
    for (var keyIndex in keys) {
      var key = keys[keyIndex];
      var item = {};
      item[key] = props.object[key];
      res.push(item);
    }
    setProductTypes([...res]);
  }, []);
  const bgColor = colorCode[props.category_name];
  let divStyle = {
    width: 100 / 18 + "%",
    margin: "0%",
    padding: "0",
    borderLeft: "1px #000000 solid",
    textAlign: "center",
    backgroundColor: bgColor,
    color: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  let containerStyle = {
    width: 100 / 8 + "%",
    margin: "0%",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: bgColor,
    alignItems: "center",
    whiteSpace: "pre",
    color: "#000000",
    borderLeft: "1px #000000 solid",
  };

  return (
    <div className="accordion-item m-0" style={{ width: "100%"}}>
      <div className="accordion-title" style={{ backgroundColor: bgColor, color: "#000000" }} onClick={() => setIsActive(!isActive)}>
        <div>
          {isActive ? <i className="pi pi-chevron-down"></i> : <i className="pi pi-chevron-right"></i>} {props.category_name}
        </div>
      </div>

      {isActive && (
        <div
          className="accordion-content"
          key="test"
          style={{
            borderLeft: "1px solid " + bgColor,
            borderRight: "1px solid " + bgColor,
            borderBottom: "1px solid " + bgColor,
            borderRadius: "0px 0px 15px 15px",
            margin: "0%",
          }}
        >
          <div style={{ display: "flex", width: "100%", border: "1px #000000 solid", margin: "0%" }}>
            <div style={containerStyle}>
              <p>Teiken</p>
            </div>
            <div style={containerStyle}>
              <p>Produk</p>
            </div>
            {weeks.map(function (item, index) {
              if (index === 0) {
                return (
                  <div key={item + "" + index} style={divStyle}>
                    <p>Voor-Plant</p>
                  </div>
                );
              }
              if (index === 1) {
                return (
                  <div key={item + "" + index} style={divStyle}>
                    <p>Plant</p>
                  </div>
                );
              } else {
                return (
                  <div key={item + "" + index} style={divStyle}>
                    <p>{index - 1}</p>
                  </div>
                );
              }
            })}
          </div>

          {productTypes.map((item, index) => {
            return <ProductTypeAccordianPrint key={Math.random()} bgColor={bgColor} item={item} />;
          })}
        </div>
      )}
    </div>
  );
};

export default SummaryAccordianPrint;
