import React, { useState } from "react";
import CheckBoxSection from "./CheckBoxSection";
import AccordianContent from "./AccordianContent";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "./Accordian.css";
import { useEffect } from "react";

// import PlanProducts from './PlanProducts';

const ProductTypeAccordianPrint = (props) => {
  const [isActive, setIsActive] = useState(true);
  const choices = props.choices;

  const cardStyle = { marginBottom: "1%" };
  const [productType, setProductType] = useState(Object.keys(props.item)[0]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    var res = [];
    var keys = Object.keys(props.item[productType]);
    for (var keyIndex in keys) {
      var key = keys[keyIndex];
      var obj = {};
      obj["name"] = key;
      obj["weeks"] = props.item[productType][key]["weeks"];

      res.push(obj);
    }
    setProducts([...res]);
  }, []);

  let divStyle = {
    width: 100 / 18 + "%",
    borderLeft: "1px #000000 solid",
    wordWrap: "break-word",
    paddingLeft: "0",
    paddingRight: "0%",
    backgroundColor: props.bgColor,
    color: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  let containerStyle = {
    width: 100 / 8 + "%",
    padding: "0%",
    display: "flex",
    wordWrap: "break-word",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: props.bgColor,
    color: "#000000",
    borderLeft: "1px #000000 solid",
  };

  const buttonStyle = { width: "100%", backgroundColor: props.bgColor, borderColor: props.bgColor, marginTop: "2%" };
  return (
    <div className="accordion-content" key="test" style={{ padding: "0%" }}>
      {products.map((product, index) => {
        return (
          <div key={Math.random()} style={{ width: "100%" }}>
            <div style={{ display: "flex", width: "100%", border: "1px #000000 solid" }}>
              <div
                key={Math.random()}
                style={{
                  width: 100 / 8 + "%",
                  padding: "0%",
                  textAlign: "center",
                  borderLeft: "1px #000000 solid",
                  backgroundColor: props.bgColor,
                  color: "#000000",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ fontSize: "0.8rem", wordWrap: "break-word", margin: "0", padding: "0%" }}>{productType.split("(")[0]}</p>
                {productType.indexOf("(") !== -1 ? (
                  <>
                    <p style={{ fontSize: "0.8rem", wordWrap: "break-word", margin: "0" }}>({productType.split("(")[1]}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div key={Math.random()} style={containerStyle}>
                <p style={{ fontSize: "0.8rem", wordWrap: "break-word" }}>{product.name}</p>
              </div>
              {product.weeks.map((week, index) => {
                if (week === "") {
                  return (
                    <div key={Math.random()} style={divStyle}>
                      <p id="label"></p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={Math.random()}
                      style={{
                        width: 100 / 18 + "%",
                        padding: "0%",
                        textAlign: "center",
                        borderLeft: "1px #000000 solid",
                        wordWrap: "break-word",
                        paddingLeft: "0%",
                        paddingRight: "0%",
                        backgroundColor: props.bgColor,
                        color: "#000000",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p id="label" style={{ fontSize: "0.8rem", wordWrap: "break-word", margin: "0" }}>
                        {week.split(" ")[0]}
                      </p>
                      <p id="label" style={{ fontSize: "0.8rem", wordWrap: "break-word", margin: "0" }}>
                        {week.split(" ")[1]}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductTypeAccordianPrint;
