import { Button } from "primereact/button";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import "./pastOrders.css";
import DuplicatePlanView from "./DuplicatePlanView";
import "../Design.css";
import { ORDER_STATUS } from "../../../constants/OrderStatus";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

const PastOrderCard = (props) => {
  const GreenColor = "#00a352";
  const [planName, setPlanName] = useState("");
  const [token] = useContext(UserContext);
  const [plantingDetail, setPlantingDetail] = useState(null);
  const [plantingDate, setPlantingDate] = useState(null);
  const [creationDate, setCreationDate] = useState(null);
  const [, setIndividualOrderNumber] = useState(null);
  const [farmer, setFarmer] = useState(null);
  const [dupPlanBool, setDupPlanBool] = useState(null);
  const [planStatus, setPlanStatus] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isHeader] = useState(props.isHeader == null ? false : props.isHeader);
  const [planDetails] = useState(props.planDetails);
  const [archived] = useState(props.archived);
  const [readOnly] = useState(props.readOnly);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (dupPlanBool === false) {
      if (props.getAllPlans != null) {
        props.getAllPlans();
      }
    }
  }, [dupPlanBool]);

  useEffect(() => {
    setPlanName(props.planDetails.planName);
    getPlantingDetails(props.planDetails.planting_id);
    var pDate = new Date(props.planDetails.plantingDate);
    setPlantingDate(pDate.toISOString().split("T")[0]);
    setIndividualOrderNumber(props.planDetails.id);
    var cDate = new Date(props.planDetails.dateCreated);
    setCreationDate(cDate.toISOString().split("T")[0]);
    const orderStatus = Object.entries(ORDER_STATUS).find(
      ([key, value]) => value === props.planDetails.orderStatus_id
    );
    if (orderStatus) {
      const [key] = orderStatus;
      //wanneer status verander, re-render die page
      setPlanStatus(key);
    }
    
  }, []);

  useEffect(() => {
    const orderStatus = Object.entries(ORDER_STATUS).find(
      ([key, value]) => value === props.planDetails.orderStatus_id
    );

    if (orderStatus) {
      const [key] = orderStatus;
      //wanneer status verander, re-render die page - werk nie as status direk geinit word deur props nie
      setPlanStatus(key);
    }
  }, [props.changeFlag, props.planDetails.orderStatus_id]);

  const deletPlanRequest = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var url = "/api/delete_plan/" + id;
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      //console.log('There is an error')
    } else {
      let index = props.allPlans.indexOf(props.planDetails);
      let temp = props.allPlans;
      temp.splice(index, 1);
      props.setAllPlans([...temp]);
    }
  };

  const archivePlanRequest = async (id, archive) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        planting_id: planDetails.planting_id,
        planName: planDetails.planName,
        plantingDate: planDetails.plantingDate,
        archived: archive,
        orderStatus_id: planDetails.orderStatus_id,
        crop_id: props.cropId?props.cropId:1
      }),
    };
    var url = "/api/update_plan_details/" + id;
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      //console.log('There is an error')
    } else {
      let index = props.allPlans.indexOf(props.planDetails);
      let temp = props.allPlans;
      temp.splice(index, 1);
      props.setAllPlans([...temp]);
    }
  };

  const getPlantingDetails = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var url = "/api/planting_details/" + id;
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setPlantingDetail({ ...data[0] });
      get_farmer_from_planting_id(data[0].id);
    }
  };

  const get_farmer_from_planting_id = async (id) => {
    if(!props.hideSpinner)
      setShowSpinner(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var url =
      "/api/get_farmer_from_planting_id/{planting_id}?planting_detail_id=" + id;
    const response = await fetch(url, requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setFarmer({ ...data });
    }
    if(!props.hideSpinner)
      setShowSpinner(false);
  };

  const deletePlan = () => {
    deletPlanRequest(props.planDetails.id);
  };

  const archivePlan = (archive) => {
    archivePlanRequest(props.planDetails.id, archive);
  };

  const duplicatePlan = () => {
    setDupPlanBool(true);
  };

  const EditPlan = () => {
    props.setPlanDetails(props.planDetails);
    props.setPath("planView");
  };

  const UpdateOrderStatus = () => {
    setIsSelected(!isSelected);

    let allPlansCopy = [...props.allPlans];
    var currentPlan = allPlansCopy.find((i) => i.id === props.planDetails.id);

    //add new property
    currentPlan.selected = !isSelected;
    props.setAllPlans(allPlansCopy);
  };

  if (showSpinner)
    return (
      <>
        <div className="d-flex flex-column min-vh-90 min-vw-100">
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );

  return (
    <>
      {farmer !== null ? (
        <>
          <Dialog
            header={"Dupliseer Plan " + props.planDetails.planName}
            visible={dupPlanBool}
            style={{ width: "80vw" }}
            onHide={() => setDupPlanBool(false)}
          >
            <DuplicatePlanView
              oldPlan={props.planDetails}
              setDupPlanBool={setDupPlanBool}
              setPlanDetails={props.setPlanDetails}
              oldPlantingDetail={plantingDetail}
              cropId={props.cropId}
              crops={props.crops}
            />
          </Dialog>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            <div
              className="row pastOrderCard"
              style={{
                marginLeft: "1%",
                height: "100%",
                borderLeft: "2px solid #00a352",
              }}
            >
              <div
                className={`col-lg-8 pt-3 pb-3 ${
                  isHeader === true ? "" : "d-none"
                }`}
              >
                <h5 style={{ marginLeft: "1%" }}>
                  <b>{planName.toUpperCase()}</b>
                </h5>
                <h6 style={{ marginLeft: "1%" }}>
                  Klient: {plantingDetail.farmerRel.businessName} | Gewas:{" "}
                  {plantingDetail.crop.toUpperCase()} | Area:{" "}
                  {plantingDetail.farmSize} (HA)
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Plaas: {farmer.farmName} | Kultivar(s):{" "}
                  {plantingDetail.cultivar.toUpperCase()} | Kampe:{" "}
                  {plantingDetail.circles} | Aanplantdatum: {plantingDate}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Verwysingnommer: {props.planDetails.id}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Bestelling vordering: {props.orderStatus}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Besteldatum: {creationDate} | Bestelnommer:{" "}
                  {("0000" + props.planDetails.id).slice(-10)}
                </h6>
                {/* Not sure where this cvomes from */}
                <h6
                  className={`${props.note == null ? "d-none" : ""}`}
                  style={{ marginLeft: "1%" }}
                >
                  Kommentaar:{" "}
                </h6>
              </div>

              <div
                className={`col-lg-8 pt-3 pb-3 ${
                  isHeader === false ? "" : "d-none"
                }`}
              >
                <h5 style={{ marginLeft: "1%" }}>
                  <b>PROGRAM NAAM: {planName.toUpperCase()}</b>
                </h5>
                <h6 style={{ marginLeft: "1%" }}>
                  Klient: {plantingDetail.farmerRel.businessName} | Gewas:{" "}
                  {plantingDetail.crop.toUpperCase()} | Area:{" "}
                  {plantingDetail.farmSize} (HA)
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Plaas: {farmer.farmName} | Kultivar(s):{" "}
                  {plantingDetail.cultivar.toUpperCase()} | Kampe:{" "}
                  {plantingDetail.circles} | Aanplantdatum: {plantingDate}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Verwysingnommer: {("0000000000" + planDetails.id).slice(-7)}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Bestelling vordering: {planStatus}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>
                  Besteldatum: {creationDate} | Bestelnommer:{" "}
                  {("0000000000" + planDetails.id).slice(-7)}
                </h6>
                <h6 style={{ marginLeft: "1%" }}>Kommentaar:</h6>
              </div>

              <div className={`col-lg-4 ${isHeader === true ? "d-none" : ""}`}>
                <div className="row">
                  <div className="col-lg-4">
                    <Button
                      icon="pi pi-trash"
                      label={archived ? "VERWYDER PERMANENT" : "ARGIEF"}
                      disabled={
                        archived &&
                        planDetails.completedPlanProductRel.some((item) => {
                          if (item.order_id > 0) {
                            return true;
                          }
                          return false;
                        })
                      }
                      iconPos="top"
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "1%",
                        outline: "none",
                      }}
                      className={`p-button-text ${readOnly ? "d-none" : ""}`}
                      onClick={() => {
                        if (archived) deletePlan();
                        else archivePlan(true);
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Button
                      icon="pi pi-book"
                      label={planStatus !== "WAG" ? "VERTOON" : "WYSIG"}
                      iconPos="top"
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "1%",
                        outline: "none",
                      }}
                      className={`p-button-text ${archived ? "d-none" : ""} ${
                        readOnly ? "d-none" : ""
                      }`}
                      onClick={EditPlan}
                    />
                    <Button
                      icon="pi pi-book"
                      label="SKUIF TERUG NA PROGRAMME"
                      iconPos="top"
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "1%",
                        outline: "none",
                      }}
                      className={`p-button-text ${archived ? "" : "d-none"} ${
                        readOnly ? "d-none" : ""
                      }`}
                      onClick={() => archivePlan(false)}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Button
                      icon="pi pi-copy"
                      label="DUPLISEER"
                      iconPos="top"
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "0%",
                        outline: "none",
                      }}
                      className={`p-button-text ${archived ? "d-none" : ""} ${
                        readOnly ? "d-none" : ""
                      }`}
                      onClick={duplicatePlan}
                    />
                  </div>
                </div>
                <div className="row top-buffer">
                  <div className="col-lg-3">{isSelected}</div>
                  <div className="col-lg-9 text-start">
                    <div
                      className={`checkbox ${
                        planStatus !== "WAG" ? "d-none" : ""
                      } ${readOnly ? "d-none" : ""} ${
                        archived ? "text-start" : ""
                      }`}
                    >
                      <label className="p-3" style={{ display: "inline" }}>
                        <i
                          className={`pi pi-shopping-cart float-right pe-2 
                          ${archived ? "d-none" : ""}
                          ${
                            planDetails.completedPlanProductRel !== undefined &&
                            planDetails.completedPlanProductRel.length === 0
                              ? "d-none"
                              : ""
                          }
                          `}
                          style={{
                            color: GreenColor,
                            borderColor: "rgba(0,0,0,0)",
                            outline: "none",
                          }}
                        ></i>
                        <i
                          className={`pi pi-apple float-right pe-2 
                          ${archived ? "d-none" : ""}
                          ${
                            planDetails.completedPlanProductRel !== undefined &&
                            planDetails.completedPlanProductRel.length === 0
                              ? ""
                              : "d-none"
                          }
                          `}
                          style={{
                            color: GreenColor,
                            borderColor: "rgba(0,0,0,0)",
                            outline: "none",
                          }}
                        ></i>
                        
                        <span
                          className={`${"blue"}                        `}
                          style={{
                            marginRight: "1%",
                            outline: "none",
                            fontWeight: "700",
                          }}
                        >
                          {archived
                            ? "GEEN BESTELLING"
                            : planDetails.completedPlanProductRel !==
                                undefined &&
                              planDetails.completedPlanProductRel.length === 0
                            ? "ONVOLTOOID"
                            : "MERK VIR BESTELLING"}
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        className={`checkbox pull-right w-25 
                        ${archived ? "d-none" : ""}
                        ${
                          planDetails.completedPlanProductRel !== undefined &&
                          planDetails.completedPlanProductRel.length === 0
                            ? "d-none"
                            : ""
                        }
                        `}
                        onChange={UpdateOrderStatus}
                        defaultChecked={isSelected}
                      />
                    </div>

                    {/* Bestel Seleksie */}
                    <div
                      className={`checkbox 
                      ${planStatus !== "BESTEL" ? "d-none" : ""}
                      `}
                    >
                      <label className="p-3" style={{ display: "inline" }}>
                        <span
                          className="blue"
                          style={{
                            marginRight: "1%",
                            outline: "none",
                            fontWeight: "700",
                          }}
                        >
                          BESTEL SELEKSIE{" "}
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        className={`checkbox pull-right ${
                          planStatus !== "WAG" ? "d-none" : ""
                        }`}
                        checked={true}
                        onChange={() => setPlanStatus("WAG")}
                      />
                    </div>
                    <div
                      className={`checkbox ${
                        planStatus !== "INGEDIEN" ? "d-none" : ""
                      }`}
                    >
                      <label className="p-3" style={{ display: "inline" }}>
                        <span
                          className="blue"
                          style={{
                            marginRight: "1%",
                            outline: "none",
                            fontWeight: "700",
                          }}
                        >
                          INGEDIEN. WAG VIR TERUGVOERING{" "}
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        className={`checkbox pull-right ${
                          planStatus !== "WAG" ? "d-none" : ""
                        }`}
                        checked={true}
                        onChange={() => setPlanStatus("WAG")}
                      />
                    </div>
                    <div
                      className={`checkbox ${
                        planStatus !== "GOEDGEKEUR" ? "d-none" : ""
                      }`}
                    >
                      <label className="p-3" style={{ display: "inline" }}>
                        <span
                          className="approved_color"
                          style={{
                            marginRight: "1%",
                            outline: "none",
                            fontWeight: "700",
                          }}
                        >
                          GOEDGEKEUR
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        className={`checkbox pull-right ${
                          planStatus !== "WAG" ? "d-none" : ""
                        }`}
                        checked={true}
                        onChange={() => setPlanStatus("WAG")}
                      />
                    </div>
                    <div
                      className={`checkbox ${
                        planStatus !== "AFGEKEUR" ? "d-none" : ""
                      }`}
                    >
                      <label className="p-3" style={{ display: "inline" }}>
                        <span
                          className="declined_color"
                          style={{
                            marginRight: "1%",
                            outline: "none",
                            fontWeight: "700",
                          }}
                        >
                          AFGEKEUR
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        className={`checkbox pull-right ${
                          planStatus !== "WAG" ? "d-none" : ""
                        }`}
                        checked={true}
                        onChange={() => setPlanStatus("WAG")}
                      />
                    </div>
                    <div
                      className={`checkbox ${
                        planStatus !== "KANSELLEER" ? "d-none" : ""
                      }`}
                    >
                      <label className="p-3" style={{ display: "inline" }}>
                        <span
                          className="cancelled_color"
                          style={{
                            marginRight: "1%",
                            outline: "none",
                            fontWeight: "700",
                          }}
                        >
                          KANSELLEER
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        className={`checkbox pull-right ${
                          planStatus !== "WAG" ? "d-none" : ""
                        }`}
                        checked={true}
                        onChange={() => setPlanStatus("WAG")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PastOrderCard;
