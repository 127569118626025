import { Button } from "primereact/button";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import { Dropdown } from "rsuite";
import { ORDER_STATUS } from "../../../../constants/OrderStatus";
import ConsolidatedOrderApi from "../../../../API/ConsolidatedOrderApi";
import LoadingSpinner from "../../LoadingSpinner";

import "../../Past Orders/pastOrders.css";
import "./ConsolidatedOrder.css";
import "rsuite/dist/rsuite.min.css";

const ConsolidatedOrderCard = (props) => {
  const GreenColor = "#00a352";
  const [token] = useContext(UserContext);
  const [planStatus, setPlanStatus] = useState(props.orderStatus);
  const [userRoles] = useState(props.userRoles);
  const [note, setNote] = useState(props.note);
  const [agentDetails, setAgentDetails] = useState(null);
  const [parentAgentDetails, setParentAgentDetails] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [spinnerText,setSpinnerText] = useState("Creating MyPack deal ...")

  const [cpqResponse, setCpqResponse] = useState(props.cpqResponse);
  

  const getAgentDetails = async (token, id) => {
    if (id == null) return;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_agent/" + id, requestOptions);
    const agent = await response.json();

    if (!response.ok) {
      console.log(
        "There is an error on the ConsolidatedView page fetching agent data"
      );
    } else {
      setAgentDetails(agent);
      if (agent.parent_id !== null)
        getAgentParentDetails(token, agent.parent_id);
    }
  };

  const getAgentParentDetails = async (token, id) => {
    if (id == null) return;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "/api/get_agent/" + id,

      requestOptions
    );
    const agent = await response.json();

    if (!response.ok) {
      console.log(
        "There is an error on the ConsolidatedView page fetching agent data"
      );
    } else {
      setParentAgentDetails(agent);
    }
  };

  useEffect(() => {
    getAgentDetails(token, props.consolidatedOrderHeader.agent_id);
  }, []);

  const updateOrderStatus = async (selectedValue) => {
    setPlanStatus(
      Object.entries(ORDER_STATUS).filter(
        (i) => i[1] === parseInt(selectedValue)
      )[0][0]
    );

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        //convert value to key
        orderStatus_id: Object.entries(ORDER_STATUS).filter(
          (i) => i[1] === parseInt(selectedValue)
        )[0][1],
        note: note,
        merchant_id: -1,
        depot_id: -1,
        dateCreated: new Date().getTime(),
        invoiceValue: 0,
        area: 0,
        numberOfPrograms: 0,
        archived: props.archived,
        crop_id: props.cropId
      }),
    };
    var url =
      "/api/update_consolidated_order_header/" +
      props.consolidatedOrderHeaderId;
    setSpinnerText("Updating order header ...")
    setShowSpinner(true);
    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          props.setChangeOrderListState(
            props.changeOrderListState === false ? true : false
          );
          return response.json();
        }
        else console.log("Update of order status failed");
      })
      .finally(() => setShowSpinner(false));
  };

  const archivePlan = (archive) => {
    archiveOrder(
      props.consolidatedOrderHeader.id,
      props.consolidatedOrderHeader.merchant_id,
      props.consolidatedOrderHeader.depot,
      props.consolidatedOrderHeader.dateCreated,
      props.consolidatedOrderHeader.orderStatus_id,
      props.consolidatedOrderHeader.invoiceValue,
      props.consolidatedOrderHeader.note,
      props.consolidatedOrderHeader.area,
      props.consolidatedOrderHeader.numberOfPrograms,
      archive
    );
  };

  const archiveOrder = async (
    id,
    merchant_id,
    depot,
    dateCreated,
    orderStatus_id,
    invoiceValue,
    note,
    area,
    numberOfPrograms,
    archive
  ) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        merchant_id: merchant_id,
        depot: depot,
        dateCreated: dateCreated,
        orderStatus_id: orderStatus_id,
        invoiceValue: invoiceValue,
        note: note,
        area: area,
        numberOfPrograms: numberOfPrograms,
        archived: archive,
        crop_id: props.cropId
      }),
    };
    var url = "/api/update_consolidated_order_header/" + id;
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      console.log("There was an error in CobnsolidateedOrderCard.archiveOrder");
    } else {
      props.setChangeOrderListState(
        props.changeOrderListState === false ? true : false
      );
    }
  };

  const updateConsolidatedOrderHeader = async (
    id,
    merchant_id,
    depot,
    dateCreated,
    orderStatus_id,
    invoiceValue,
    note,
    area,
    numberOfPrograms,
    archive,
    cpqResponse
  ) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        merchant_id: merchant_id,
        depot: depot,
        dateCreated: dateCreated,
        orderStatus_id: orderStatus_id,
        invoiceValue: invoiceValue,
        note: note,
        area: area,
        numberOfPrograms: numberOfPrograms,
        archived: archive,
        cpqResponse: cpqResponse,
        crop_id: props.cropId
      }),
    };
    var url = "/api/update_consolidated_order_header/" + id;
    const response = await fetch(url, requestOptions);
  
    if (!response.ok) {
      console.log(
        "There was an error in SubmitConsolidatedOrderView.updateConsolidatedOrderHeader"
      );
    } else {
      console.log("Update ConsolidatedOrder Header was successful");
    }
  };

  const redoCpqOrder = async ()=>{
    setSpinnerText("Creating MyPack order ...")
    setShowSpinner(true);      
    await ConsolidatedOrderApi.createCommercialTermMypackOrder(
      props.consolidatedOrderHeader.id
     ).then((result)=>{
        setCpqResponse(result)
        updateConsolidatedOrderHeader(
          props.consolidatedOrderHeader.id,
          props.consolidatedOrderHeader.merchant_id,
          props.consolidatedOrderHeader.depot,
          props.consolidatedOrderHeader.dateCreated,
          props.consolidatedOrderHeader.orderStatus_id,
          props.consolidatedOrderHeader.invoiceValue,
          props.consolidatedOrderHeader.note,
          props.consolidatedOrderHeader.area,
          props.consolidatedOrderHeader.numberOfPrograms,
          props.consolidatedOrderHeader.archive ? props.consolidatedOrderHeader.id.archive : false,
          result
         );
        setShowSpinner(false);
     }).catch((error)=>{
        setCpqResponse(error)
        updateConsolidatedOrderHeader(
          props.consolidatedOrderHeader.id,
          props.consolidatedOrderHeader.merchant_id,
          props.consolidatedOrderHeader.depot,
          props.consolidatedOrderHeader.dateCreated,
          props.consolidatedOrderHeader.orderStatus_id,
          props.consolidatedOrderHeader.invoiceValue,
          props.consolidatedOrderHeader.note,
          props.consolidatedOrderHeader.area,
          props.consolidatedOrderHeader.numberOfPrograms,
          props.consolidatedOrderHeader.archive ? props.consolidatedOrderHeader.id.archive : false,
          error
         );
      setShowSpinner(false)
    });
  };

  return (
    <div
      className="container"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
        position: "relative"
      }}
    >
      {showSpinner && 
      <LoadingSpinner loadingText={spinnerText}/>
      }
      <div
        className="row pastOrderCard"
        style={{
          marginLeft: "1%",
          height: "100%",
          borderLeft: "2px solid #00a352",
        }}
      >
        <div className={`col-lg-6 pt-3 pb-3 `}>
          <h6
            className={`${
              userRoles.find(
                (i) => i === "AREA MANAGER" || i === "CAMPAIGN LEAD"
              )
                ? ""
                : "d-none"
            }`}
            style={{ marginLeft: "1%" }}
          >
            Sales Manager: {parentAgentDetails?.name}
          </h6>
          <h6
            className={`${
              userRoles.find(
                (i) =>
                  i === "SALES MANAGER" ||
                  i === "AREA MANAGER" ||
                  i === "CAMPAIGN LEAD"
              )
                ? ""
                : "d-none"
            }`}
            style={{ marginLeft: "1%" }}
          >
            Agent: {agentDetails?.name} | Handelaar:{" "}
            {agentDetails?.merchantRel?.name} | Tak: {agentDetails?.branch}
          </h6>
          <h6 style={{ marginLeft: "1%" }}>
            Bestelling verwysing: {props.consolidatedOrderHeaderId} | Bestel
            datum: <span className="text-nowrap">{props.dateCreated}</span>
          </h6>
          <h6 style={{ marginLeft: "1%" }}>
            Aantal programme: {props.numberOfPrograms} | Area (Ha): {props.area}{" "}
            | <span className="text-nowrap">MyPack waarde: {"R"} {props.invoiceValue}</span>
            
          </h6>
          <h6 style={{ marginLeft: "1%" }}>
            Bestelling vordering: {planStatus}
          </h6>
          <h6 style={{ marginLeft: "1%" }}>
            Kommentaar:
            <input
              className="ms-2 w-50"
              type="text"
              value={note}
              onChange={(e) => {
                setNote(e.target.value.toString());
              }}
            />
          </h6>
          <label
            className={`${
              cpqResponse !== "OK" &&
              cpqResponse !== "MyPack deal created"
                ? "text-danger"
                : ""
            } d-inline d-block`}
            style={{ marginLeft: "1%", width:"100%", wordWrap: "break-word"}}
          >
            {cpqResponse === "OK"
              ? "MyPack deal created"
              : cpqResponse==null?"Not successful":cpqResponse}
          </label>
        </div>

        <div className={`col-lg-6`}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-5`}>
                <Button
                  className={`${
                    userRoles.find((i) => i === "AGENT") ? "" : "d-none"
                  }  
                  ${cpqResponse && cpqResponse=== "OK"?"":"d-none"}
                ${
                  planStatus === "WAG"
                    ? "order_buttons_in_wait_status"
                    : planStatus === "AFGEKEUR"
                    ? "order_buttons_in_rejected_status"
                    : planStatus === "KANSELLEER"
                    ? "order_buttons_in_cancelled_status"
                    : "order_buttons"
                } 
                w-75  h-30 d-inline-block mt-5`}
                  disabled={true}
                >
                  {planStatus}
                </Button>
                <Button
                  
                  className={`${
                    userRoles.find((i) => i === "AGENT") ? "" : "d-none"
                  }  
                ${(cpqResponse && cpqResponse!== "OK")||!cpqResponse?"":"d-none"}
                bg-danger
                w-75  h-30 d-inline-block mt-5`}
                onClick={()=>redoCpqOrder()}
                >
                  Redo request
                </Button>
                <Dropdown
                  style={{ display: "block" }}
                  className={` bg-transparent ${
                    userRoles.find((i) => i === "AGENT") ? "d-none" : ""
                  }`}
                  trigger="hover"
                  classPrefix="btn"
                  renderToggle={() => (
                    <div
                      style={{float: "left"}}
                      className={`rounded ${
                        planStatus === "WAG"
                          ? "order_buttons_in_wait_status"
                          : planStatus === "AFGEKEUR"
                          ? "order_buttons_in_rejected_status"
                          : planStatus === "KANSELLEER"
                          ? "order_buttons_in_cancelled_status"
                          : "order_buttons"
                      } btn w-75  h-30 mt-5 visible`}
                    >
                      <label>{planStatus}</label>
                    </div>
                  )}
                >
                  <Dropdown.Item eventKey="Ingedien" className="d-none">
                    Ingedien. Wag vir terugvoer
                  </Dropdown.Item>

                  <Dropdown.Item
                    id={ORDER_STATUS.GOEDGEKEUR}
                    eventKey="Goedgekeur"
                    onClick={(e) => updateOrderStatus(e.target.id)}
                    className={`${planStatus === "GOEDGEKEUR" ? "d-none" : ""}`}
                  >
                    Goedgekeur
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={ORDER_STATUS.AFGEKEUR}
                    eventKey="Afgekeur"
                    onClick={(e) => updateOrderStatus(e.target.id)}
                    className={`${planStatus === "AFGEKEUR" ? "d-none" : ""}`}
                  >
                    Afgekeur
                  </Dropdown.Item>
                  <Dropdown.Item
                    id={ORDER_STATUS.KANSELLEER}
                    eventKey="Kanselleer"
                    onClick={(e) => updateOrderStatus(e.target.id)}
                    className={`${planStatus === "KANSELLEER" ? "d-none" : ""}`}
                  >
                    Kanselleer
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <div className={`col-lg-7`}>
                <div className="row">
                  <div className="col-lg-6">
                    <Button
                      icon="pi pi-trash"
                      label="ARGIEF"
                      iconPos="top"
                      className={`mt-5 ${props.archived ? "d-none" : ""}`}
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "1%",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => {
                        archivePlan(true);
                      }}
                    />

                    <Button
                      icon="pi pi-book"
                      label="SKUIF TERUG NA PROGRAMME"
                      iconPos="top"
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "1%",
                        outline: "none",
                      }}
                      className={`p-button-text ${
                        props.archived ? "" : "d-none"
                      }`}
                      onClick={() => archivePlan(false)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Button
                      icon="pi pi-search"
                      label="BESIGTIG"
                      iconPos="top"
                      className="mt-5"
                      style={{
                        color: GreenColor,
                        borderColor: "rgba(0,0,0,0)",
                        marginRight: "1%",
                        outline: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => {
                        props.setConsolidatedOrderHeader(
                          props.consolidatedOrderHeader
                        );
                        props.setPath("consolidatedOrderView");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsolidatedOrderCard;
