import React from "react";
import WhiteLogo from "../Images/SyngentaLogoWhite.png";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import MacadamiaImage from "../../src/Images/macadamias.png";
import PotatoImage from "../../src/Images/potatoes.png";
import MieliesImage from "../../src/Images/mielies.png";

const Header = (props) => {
  const GreenColor = "#00a352";
  
  const selectedCropTemplate = (option, props) => {
    if (option) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img
                alt={option.name}
                src={option.id === 1 ? PotatoImage : (option.id === 2 ? MacadamiaImage: MieliesImage)}
                style={{ width: "30px", height: "20px" }}
              />
            </div>
            <div className="col-8">
              <div>{option.name}</div>
            </div>
          </div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const handleCropChange = (e) => {
    if (props.crops.length === 0) return;
    props.setCropId(e);
    window.localStorage.setItem(props.agent.email, e);
    if(props.onCropChange)
      props.onCropChange(e);
  };

  const cropOptionTemplate = (option) => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3">
            <img
              alt={option.name}
              src={option.id === 1 ? PotatoImage : MacadamiaImage}
              style={{ width: "30px", height: "20px" }}
            />
          </div>
          <div className="col-9">
            <div>{option.name}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        color: "#FFFFFF",
        backgroundColor: "#00a352",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          color: "#FFFFFF",
          backgroundColor: "#00a352",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <Button
          style={{
            marginLeft: "1%",
            fontSize: "20px",
            backgroundColor: "#00a352",
            borderColor: "#00a352",
          }}
          icon="pi pi-arrow-left"
          onClick={() => {
            props.setPath(props.backPath);
          }}
        />

        <p className={"mb-0"} style={{ marginLeft: "2%", fontSize: "20px" }}>
          {props.title}
        </p>
      </div>
      <div
        style={{
          width: "100%",
          color: "#FFFFFF",
          height: "30px",
          backgroundColor: "#00a352",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <Dropdown
          style={{
            width: "200px",
            color: GreenColor,
            borderColor: GreenColor,
            marginRight: "7%",
          }}
          options={props.crops}
          optionLabel="name"
          optionValue="id"
          value={props.cropId}
          valueTemplate={selectedCropTemplate}
          itemTemplate={cropOptionTemplate}
          onChange={(e) => {
            handleCropChange(e.target.value);
          }}
          placeholder={"Crop"}
          className={props.crops === undefined ?"d-none":""}
        />

        <img
          alt=""
          src={WhiteLogo}
          style={{ height: "100%", marginRight: "13%" }}
        />
      </div>
    </div>
    // <div style={{ width:'100%', height:'6vh',color:'#FFFFFF', backgroundColor:'#00a352', display:'flex', flexDirection:'row',justifyContent:'flex-start', alignItems:'center', alignContent:'center', alignSelf:'center'}}>
    // <Button style={{marginLeft:'1%', backgroundColor:'#00a352', borderColor:'#00a352'}} icon="pi pi-arrow-left" onClick={()=>{props.setPath(props.backPath)}}/>
    // <p style={{marginLeft:'2%', fontSize:'1.5vw', whiteSpace:'nowrap'}}>{props.title}</p>
    // <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
    // <img src={WhiteLogo}  alt = 'Syngenta Logo' style={{width:'8%', marginRight:'1%'}}/>
    // </div>

    // </div>
  );
};

export default Header;
