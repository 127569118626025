import React, { useState } from "react";
import AccordianContent from "./AccordianContent";
import { Button } from "primereact/button";
import "./Accordian.css";
import { useEffect } from "react";

import PlanProducts from "./PlanProducts";

const ProductSelect = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [token] = useState(localStorage.getItem("agentToken"));
  const [selection, setSelection] = useState([]);
  const [planProducts, setPlanProducts] = useState([]);
  const [filteredChoices, setFilteredChoices] = useState([]);
  
  const getPlanProducts = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_plan_products_by_plan_id/" + props.planDetail.id, requestOptions);
    const data = await response.json();
    if (!response.ok) {
      console.log(`There is an error fetching data in getPlanProducts`)
    } else {
      setPlanProducts(data);
    }
  };
  useEffect(() => {
    setFilteredChoices(props.choices.filter((choice) => choice.category_id === props.category_id));
    getPlanProducts();
  }, []);

  useEffect(() => {
    setFilteredChoices(props.choices.filter((choice) => choice.category_id === props.category_id));
    getPlanProducts();
  }, [props.productChange]);

  const colorCode = {
    1: "#5f5900",
    2: "#5f7800",
    3: "#04b7eb",
    4: "#000064",
    5: "#ecb32a",
    6: "#5f5900",
    7: "#04b7eb",
    8: "#000064",
    9: "#6366F1",
    10: "#5f5900",
    11: "#04b7eb",
    12: "#000064",
    13: "#009b2d",
  };

  const bgColor = colorCode[props.category_id];
  const buttonStyle = { width: "100%", backgroundColor: bgColor, borderColor: bgColor, marginTop: "2%" };
  return (
    <div className="accordion-item" style={{ width: "100%" }}>
      <div className="accordion-title" style={{ border: "3px solid " + bgColor, color: bgColor }} onClick={() => setIsActive(!isActive)}>
        <div>
          {/* collection of category controls are kept on this view's parent that hosts the accordianContent which is where teiken, produk, 
              dosis and periods are kept 
              This view hosts a single item category from the collection of categories on the planView */}
          {isActive ? <i className="pi pi-chevron-down"></i> : <i className="pi pi-chevron-right"></i>} {props.category_name}
        </div>
      </div>
      {isActive && (
        <div
          className="accordion-content"
          key="test"
          style={{
            borderLeft: "3px solid " + bgColor,
            borderRight: "3px solid " + bgColor,
            borderBottom: "3px solid " + bgColor,
            borderRadius: "0px 0px 15px 15px",
          }}
        >
          {planProducts.map((item) => {
            var productWeeks = item.weeks.split(",");
            const weeksInObj = JSON.parse(productWeeks)
            var resWeeks = [];
            resWeeks = weeksInObj;
            var productChoice = filteredChoices.filter((choice) => choice.id === item.choice_id);
            if (productChoice.length > 0) {
              return (
                //read
                <PlanProducts
                  key={item.id}
                  planProducts={planProducts}
                  category_id={props.category_id}
                  category_name={props.category_name}
                  plantingDetail={props.plantingDetail}
                  planDetail={props.planDetail}
                  productType_id={productChoice[0].productType_id}
                  product_id={productChoice[0].product_id}
                  dosage={item.dosage}
                  weeks={resWeeks}
                  setSelection={setSelection}
                  plan_product_id={item.id}
                  choice_id={item.choice_id}
                  productChange={props.productChange}
                  setProductChange={props.setProductChange}
                  choices={filteredChoices}
                  buttonColor={bgColor}
                  setPlanProductChange={props.setPlanProductChange}
                  cropId={props.cropId}
                  planProduct={item}
                />
              );
            }
          })}
          
          {selection.map((item) => {
            //user input
            return (
              <AccordianContent
                planProducts={planProducts}
                key={item + "" + Math.random()}
                category_id={props.category_id}
                category_name={props.category_name}
                plantingDetail={props.plantingDetail}
                planDetail={props.planDetail}
                setSelection={setSelection}
                buttonColor={bgColor}
                productChange={props.productChange}
                setProductChange={props.setProductChange}
                cropId={props.cropId}
                periodsPerCrops={props.periodsPerCrops}
              />
            );
          })}

          {selection.length === 0 ? (
            <>
              {" "}
              <Button
                style={buttonStyle}
                onClick={() => {
                  setSelection([{}]);
                }}
                label={"KIES " + props.category_name}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductSelect;
