export const Token = localStorage.getItem("agentToken");

export const PlanDetailsAPI = {
  getAllPlans: async function (archived = false, agent_id = -1, cropId = 1) {
    return await fetch(
      "/api/get_all_plans_by_agent_id/" +
        (archived ? "true" : "false") +
        "/" +
        agent_id +
        "/" + cropId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Retrieval of all plans by agent was successful");
          return response.json().then((data)=>{
            return data;
          });
        }
      })
      .catch((error) => {
        console.error(
          "There was an error in PlanDetailsAPI.getAllPlans",
          error
        );
      });
  },
};

export default PlanDetailsAPI;
