import React, { createContext, useEffect, useState } from 'react';

export const UserContext= createContext();

export const UserProvider = (props)=>{
    const [token,setToken] = useState(localStorage.getItem('agentToken'));
    const [agent,setAgent] = useState({})
    useEffect(()=>{
        const fetchUser = async () =>{
            const requestOptions = {
                method:"GET",
                headers:{
                    "Content-Type":"application/json",
                    Authorization:"Bearer "+token,
                }
            };
            const response = await fetch("/api/agents/auth_agent", requestOptions);
            const data = await response.json()
            setAgent({...data})
            if (!response.ok){
                setToken(null);
            }

            localStorage.setItem('agentToken', token)
        };
        fetchUser();
    },[token])


    return (
        <UserContext.Provider value={[token,setToken,agent]}>
            {props.children}
        </UserContext.Provider>
    );

}
