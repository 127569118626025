import React, { useState } from "react";
import "./Accordian.css";
import { useEffect } from "react";

const ActiveIngredients = (props) => {
  const [isActive, setIsActive] = useState(true);
  const [labels, setLabels] = useState([]);

  const colorCode = {
    "AKTIEWE BESTANDELE": "#A7171A",
  };

  const bgColor = colorCode[props.category_name];

  useEffect(() => {
    if (props.periodsPerCrops) {
      let modifiedWeeklabels = [...props.periodsPerCrops];
      modifiedWeeklabels.unshift({ name: "Totaal" });
      modifiedWeeklabels.unshift({ name: "Aktiewe bestandeel" });

      setLabels([...modifiedWeeklabels]);
    } else setLabels(["No labels found"]);
  }, [props.cropId, props.periodsPerCrops, props.planProducts]);

  if (props.ingredientsUsed && props.ingredientsUsed.length > 0)
    return (
      <div style={{ paddingLeft: "1.5%", paddingRight: "1.5%" }}>
        <div className="accordion-item-extra" style={{ width: "100%" }}>
          <div className="accordion-title" style={{ backgroundColor: bgColor, color: "#FFFFFF" }} onClick={() => setIsActive(!isActive)}>
            <div>
              {isActive ? <i className="pi pi-chevron-down"></i> : <i className="pi pi-chevron-right"></i>} {props.category_name}
            </div>
          </div>
          {isActive ? (
            <div className="container-fluid">
              <div className="row my-2" name="theHeader">
                {labels.map((item, index) => {
                  return (
                    <div key={index} className={`fw-bold ${"text-start"}`} style={{ width: index === 0 ? "14%" : "8%" }}>
                      {item.name}
                    </div>
                  );
                })}
              </div>
              {props.ingredientsUsed.map(function (ingredient) {
                return (
                  <div id="theFirstColumn" className="row">
                    {props.ingredients
                      .filter((item) => item.id === ingredient.ingredient_id)
                      .map((item) => {
                        return (
                          <div key={item.id} className="text-start" style={{ width: "14%", overflow: "hidden" }}>
                            {item.name}
                          </div>
                        );
                      })}

                    <div className="text-start" style={{ width: "8%", overflow: "hidden" }}>
                      {ingredient["line_total"]
                        ? parseFloat(ingredient["line_total"])
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : ""}
                    </div>

                    {props.periodsPerCrops && props.periodsPerCrops.map(function (week, index) {
                      return (
                        <div key={index} className="text-start" style={{ width: "8%", overflow: index === 0 ? "hidden" : "visible" }}>
                          {ingredient[week.id]
                            ? parseFloat(ingredient[week.id])
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                            : ""}
                        </div>
                      );
                    })}

                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  else return <></>;
};

export default ActiveIngredients;
