import React from "react";
import Header from "../../Header";
import "./helpView.css";

const HelpView = (props) => {
  return (
    <div>
      <Header title={"HELP"} backPath={"pastOrders"} setPath={props.setPath} />
      <div></div>
      <div className="container  ms-5 mt-4">
        <div className="row  mb-3">
          <div className="col-md-12">
            <h1 className="mainHeader">Help page</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h4>Wat is MyPack?</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p className="p">
              MyPack laat gewas adviseurs toe om pasgemaakte
              gewasbeskermingsprogramme vir hulle kliente op te stel om
              buigdsaamheid en prysvoordele vir die aankoop van Syngenta
              produkte te verkry.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h4>Wat is die kriteria vir MyPack voordele?</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p className="p">
              Prysvoordele word bepaal volgens die totale waarde, waarde per
              hektaar en produkkombinasie van die bestelling.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h4>
              Addisionele korting indien ingeteken is vir Syngenta se 'Weather
              and Risk Map' diens.
            </h4>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <p className="p">
              Die gewas adviseur kwalifiseer vir addisionele korting op die
              MyPack aankope indien ingeteken is vir Syngenta se 'Weather and
              Risk Map' diens. Kontak jou Syngenta verteenwoordiger vir meer
              inligting.
            </p>
            <a href="https://www.syngenta.co.za/service/weather-and-risk-map-service">https://www.syngenta.co.za/service/weather-and-risk-map-service</a>
          </div>
        </div>

        <div className="row  mb-3">
          <div className="col-md-12">
            <h4>Hoe werk die MyPack betelling?</h4>
          </div>
        </div>

        <div className="row">
          <div className="col col-lg-1">Stap 1.</div>
          <div className="col col-lg-11">
            <p className="p">Skep 'n klient deur op '+ Skep Klient' te kliek.</p>
          </div>
          <div className="col col-lg-1">Stap 2.</div>
          <div className="col col-lg-11">
            <p className="p" >Skep 'n spuitprogram deur op '+ Skep nuwe spuitprogram' te kliek.</p>
          </div>
          <div className="col col-lg-1">Stap 3.</div>
          <div className="col col-lg-11">
            <p className="p">Voer besonderhede vir die spuitprogram in, kies 'n klient
              vanaf die kieserslys. Kliek 'Gaan voort'.</p>
          </div>
          <div className="col col-lg-1">Stap 4.</div>
          <div className="col col-lg-11">
            <p className="p">Voltooi die spuitprogram en kliek 'Gaan voort' om die
              bestelblad oop te maak.</p>
          </div>
          <div className="col col-lg-1">Stap 5.</div>
          <div className="col col-lg-11">
            <p className="p">Pas die bestelling aan volgens die aantal verpakkings van
              elke produk wat benodig word. Kliek 'Skep bestelling'.</p>
          </div>
          <div className="col col-lg-1">Stap 6.</div>
          <div className="col col-lg-11">
            <p className="p">Hersien die bestelling en kliek 'Stuur vir goedkeuring' om
              die bestelling in te dien. 'n Syngenta verteenwoordiger sal die
              program hersien en goedkeur of afkeur.</p>
          </div>
          <div className="col col-lg-1">Stap 7.</div>
          <div className="col col-lg-11">
            <p className="p">Met goedkeuring van die bestelling sal jy 'n epos met die
              bestelling en bevestiging ontvang. Die bestelling word aan die
              verspreider gestuur om die spesiale pryse vir die bestelling vas
              te vang. <br />
              Slegs die volume produk wat op die bestelling aangedui is
              kwalifiseer vir die MyPack korting.</p>
          </div>
          <div className="col col-lg-12">
          <p>Ander funksionaliteit:</p>
            <p className="p">
              - Jy kan programme 'uitvee', 'wysig' of 'dupliseer' deur op die
              onderskeie funksionaliteit langs die spuitprogram te kliek.
            </p>
          </div>
        </div>

        <div className="row">
        <div className="col-md-12">
          <h4>
            Kontak gerus jou Syngenta verteenwoordiger vir meer inligting en
            hulp.
          </h4>
        </div>
      </div>
      </div>
    </div>
  );
};

export default HelpView;