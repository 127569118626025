import { useEffect, useState, forwardRef } from "react";
import Header from "../../Header";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button } from "primereact/button";
import "./GrowerListView.css";
import GrowerCard from "./GrowerCard.js";

const GrowerListView = forwardRef(function (props, ref) {
  const [token] = useState(localStorage.getItem("agentToken"));
  const [allFarmers, setAllFarmers] = useState([]);
  const [farmerDeleted, setFarmerDeleted] = useState(false);

  useEffect(() => {
    getFarmers();
  }, []);

  useEffect(() => {
    getFarmers();
  }, [farmerDeleted]);

  const refreshAfterDelete = () => {
    setFarmerDeleted(!farmerDeleted);
  };

  const getFarmers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/get_farmers", requestOptions);
    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setAllFarmers(data);
    }
  };

  return (
    <div>
      <Header 
        title={"LYS VAN KLIENTE"} 
        backPath={"pastOrders"} 
        setPath={props.setPath} />

      <div className="row">
        <div className="col-lg-12">
          <div style={{ marginRight: "5%" }}>
            <Button
              style={{
                color: "#00a352",
                backgroundColor: "#FFFFFF",
                alignContent: "center",
                borderColor: "#00a352",
              }}
              className={`p-button-text float-end mt-4 mb-4 ms-5`}
              icon="pi pi-plus"
              label="SKEP KLIENT"
              iconPos="left"
              onClick={() => {
                props.setBackPath("growerListView");
                props.setFarm(null);
                props.setPath("farmerReg");
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <TransitionGroup
            className="mt-2"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            {allFarmers
              .sort((a, b) => (a.id < b.id ? 1 : -1))
              .map((item) => {
                props.setFarm(item);
                return (
                  <CSSTransition
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      alignSelf: "center",
                    }}
                    key={item.id}
                    timeout={1000}
                    unmountOnExit
                    classNames="my-node"
                  >
                    <GrowerCard
                      businessName={item.businessName}
                      farmName={item.farmName}
                      nearestTown={item.nearestTown}
                      contactPerson={item.contactPerson}
                      contactNumber={item.contactNumber}
                      email={item.email}
                      setPath={props.setPath}
                      setBackPath={props.setBackPath}
                      farmId={item.id}
                      refreshAfterDelete={refreshAfterDelete}
                      farm={item}
                      setFarm={props.setFarm}
                      setOpenConfirmationMessage={props.setOpenConfirmationMessage}
                      allowDelete={props.allowDelete}
                      setAllowDelete={props.setAllowDelete}
                      setActionHeader={props.setActionHeader}
                      setActionDetail={props.setActionDetail}
                      ref={ref}
                      setConfirmationButtonLabels={props.setConfirmationButtonLabels}
                      setFarmToDelete={props.setFarmToDelete}
                    />
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
});

export default GrowerListView;
