import "./CheckBox.css";
import "rc-slider/assets/index.css";
import { Checkbox } from "primereact/checkbox";

function CheckBoxesGeneric(props) {
  return (
    <div className="col-12">
      {props.weeks.map((item, index) => {
        return (
            <div key={index} className="CheckBox mb-3">
              {
                <div style={{ display: "flex", flexDirection: "column" }} className="h-100">
                  <p
                    className="d-flex aligns-items-center justify-content-center"
                    style={{
                      fontSize: "90%",
                      padding: 0,
                      margin: 0,
                      marginTop: "10px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {item.desc}
                  </p>
                </div>
              }
              <Checkbox
                inputId="cb1"
                disabled={props.disabled}
                value={index}
                checked={props.weeks[index].val}
                onChange={(e) => {
                  props.weeks[e.value].val = !props.weeks[e.value].val;
                  props.setWeeks([...props.weeks]);
                }}
              ></Checkbox>
            </div>
        );
      })}
    </div>
  );
}

export default CheckBoxesGeneric;
