import React, { useState, useEffect, useContext } from "react";
import PricingTable from "./PricingTable";
import Header from "../../Header";
import "./PricingView.css";
import { CSSTransition } from "react-transition-group";
import PastOrderCard from "../Past Orders/PastOrderCard";
import { ORDER_STATUS } from "../../../constants/OrderStatus";
import { PricingAPI } from "../../../API/PricingAPI";
import { UserContext } from "../../../context/UserContext";
import LoadingSpinner from "../LoadingSpinner";

const PriceView = (props) => {
  const [, , agent] = useContext(UserContext);
  const [agentCopy] = useState(JSON.parse(JSON.stringify(agent)));
  const [pricingProducts, setPricingProducts] = useState([]);
  const [orderPrice, setOrderPrice] = useState([]);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [agentRegPrice, setAgentRegPrice] = useState(0);
  const [weatherDiscountLost, setWeatherDiscountLost] = useState(0);
  const [farmSize, setFarmSize] = useState(0);
  const [backPath] = useState(props.backPath == null 
    ? "orderView" 
    : props.backPath);
  const [justAView] = useState(props.backPath === "submitConsolidatedOrderView");
  const [validPrices, setValidPrices] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    getOrderPricing();
  }, []);

  const getOrderPricing = async () => {
    setShowSpinner(true)
    PricingAPI.getOrderPricings(props.planDetails.id)
      .then((data) => {
        if (data === undefined) {
          console.log("Data returned from PricingAPI.getOrderPricings is empty");
          throw new Error("Data returned from getOrderPricings is empty");
        }
        if (data.detail !== undefined && data.detail === "No SAP Account linked to agent") {
          setValidPrices(false);
          return;
        }
        setOrderPrice(data["order_totals"].order_list_price);
        setDiscountPrice(data["order_totals"].order_final_discount);
        setAgentRegPrice(data["agentRegPrice"]);
        setFarmSize(
          Object.values(data["plans"]).reduce(
            (r, { farmSize }) => r + farmSize, 
            0
          )
        );
        setPricingProducts(data["product_summary"]);
        setWeatherDiscountLost(
          Object.values(data["plans"]).reduce(
            (r, { plan_weather_discount_price_lost }) => r + plan_weather_discount_price_lost, 
            0
          )
        );
      })
      .catch((error) => {
        console.error("Error fetching pricing data:", error);
      })
      .finally(() => {
        setShowSpinner(false)
      });
  };
  

  if (!validPrices) {
    return (
      <>
        <div style={{ width: "100%" }}>
          <Header 
            title={"Skep bestelling"} 
            backPath={backPath} 
            setPath={props.setPath} />
          <div 
            className="d-flex align-items-center justify-content-center" 
            style={{ height: "250px" }}>
            <div>
              <h1 className="text-danger">No SAP Account linked to agent. Unable to calculate prices</h1>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      {showSpinner && 
      <LoadingSpinner loadingText={"Calculating prices ..."} topPercentage={50}/>
      }
      <Header title={"Skep bestelling"} backPath={backPath} setPath={props.setPath} />
      <div className="container mt-3 ms-0">
        <CSSTransition timeout={1000}>
          <PastOrderCard
            planDetails={props.planDetails}
            isHeader={true}
            orderStatus={
              Object.entries(ORDER_STATUS).find(([, value]) => value === props.planDetails.orderStatus_id)?.length > 0
                ? Object.entries(ORDER_STATUS).find(([, value]) => value === props.planDetails.orderStatus_id)[0]
                : "Invalid"
            }
          />
        </CSSTransition>
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className="container" style={{ marginLeft: "5%" }}>
          <div className="row mb-3">
            <div className="col-sm-4 fw-bold">
              <div className="row">
                <div className="col-sm-9 fw-bold">Produk</div>
                <div className="col-sm-3 fw-bold">AGI</div>
              </div>
            </div>
            <div className="col-sm-1 fw-bold text-end">Verpakking</div>
            <div className="col-sm-1 fw-bold text-end">Aantal Eenhede</div>
            <div className="col-sm-1 fw-bold text-end">Bestel Volume</div>
            <div className="col-sm-1 fw-bold text-end">Lys Waarde</div>
            <div className="col-sm-1 fw-bold text-end">Afslag Waarde</div>
            <div className="col-sm-1 fw-bold text-end">MyPack Waarde</div>
            <div className="col-sm-1 fw-bold text-end">MyPack Prys (R/L)</div>
          </div>
          {pricingProducts.map((item) => {
            return (
              <div className="row">
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-9">{item.product_pack_name}</div>
                    <div className="col-sm-3">{item.AGI}</div>
                  </div>
                </div>
                {/* Verpakking */}
                <div className="col-sm-1 text-nowrap text-end">{item.full_pack_volume / item.full_pack_units + " " + item.unit}</div>

                {/* Aantal eenhede */}
                <div className="col-sm-1 text-nowrap text-end">{item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                
                <div className="col-sm-1 text-nowrap text-end">
                {(item.full_pack_units!==0?(item.quantity * item.full_pack_volume) / item.full_pack_units:0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                </div>
                <div className="col-sm-1 text-nowrap text-end">
                {
                  item.isParent === true
                    ? ""
                    : "R" + item.order_list_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                </div>
                <div className="col-sm-1 text-nowrap text-end">
                  {item.isParent === true?"":"R"+item.order_final_discount
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                </div>

                <div className="col-sm-1 text-nowrap text-end">
                  {item.isParent === true?"":"R"+item.order_final_price
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                </div>
                <div className="col-sm-1 text-nowrap text-end">
                  {item.isParent === true?"":"R"+(item.quantity > 0 ? (item.order_final_price / ((item.quantity * item.full_pack_volume) / item.full_pack_units)):0
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " "))}
                </div>
              </div>
            );
          })}
        </div>

        <PricingTable
          agent={props.agent}
          orderPrice={orderPrice}
          discountPrice={discountPrice}
          agentRegPrice={agentRegPrice}
          planDetails={props.planDetails}
          setPath={props.setPath}
          farmSize={farmSize}
          setShowIsIsSucess={props.setShowIsIsSucess}
          justAView={justAView}
          weatherDiscountLost={weatherDiscountLost}
          isCustomerRegisteredForWeatherService={agentCopy.registered && agentCopy.registered === "true"}
        ></PricingTable>
      </div>
    </div>
  );
};

export default PriceView;
