export const CropsAPI = {
  getAllCrops: async function () {
    return await fetch("/api/get_all_crops/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("agentToken"),
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
      })
      .catch((error) => {
        console.error("There was an error in CropsAPI.getAllCrops", error);
      });
  },
  getPeriodsPerCrop: async function () {
    return await fetch(`/api/get_periods_per_crop`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("agentToken"),
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
      })
      .catch((error) => {
        console.error(
          "There was an error in CropsAPI.getPeriodsPerCrop",
          error
        );
      });
  },
};

export default CropsAPI;
