import React from "react";
import "./CheckBoxRow.css";
import "rc-slider/assets/index.css";
import SummaryAccordian from "./SummaryAccord";
import ActiveIngredients from "./ActiveIngredients";

const SummaryTable = (props) => {
  const maizeCropId = 3
  return (
    <div className="col-12" style={{ marginTop: "1%", display: "block" }}>
      <>
        {Object.keys(props.displayProducts).map((category) => {
          return (
            <div key={Math.random()} style={{ paddingLeft: "1.5%", paddingRight: "1.5%" }}>
              <SummaryAccordian category_name={category} object={props.displayProducts[category]} />
              {category === "ONKRUIDDODERS" && props.cropId===maizeCropId && <ActiveIngredients
                className="pb-5"
                category_name="AKTIEWE BESTANDELE"
                planProducts={props.planProducts}
                periodsPerCrops={props.periodsPerCrops}
                object={props.displayProducts[category]}
                cropId={props.cropId}
                ingredientsUsed={props.ingredientsUsed}
                ingredients={props.ingredients}
              />}
              <></>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default SummaryTable;
