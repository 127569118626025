import { Button } from "primereact/button";
import { forwardRef, useImperativeHandle } from "react";
import { useContext} from "react";
import { UserContext } from "../../../context/UserContext";
import "rsuite/dist/rsuite.min.css";

const GrowerCard = forwardRef(function (props, ref) {
  
  const GreenColor = "#00a352";
  const [token] = useContext(UserContext);
  
  const deleteFarmer = async (farmerId) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    var url = `/api/delete_farmer_by_id/${farmerId}`;
    
    try{
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        //console.log('There is an error')
      } else {
        props.refreshAfterDelete();
      }
    }
    catch(error){
      console.error(`Error during API call: ${error.message}`);
    }
  };

  useImperativeHandle(ref, () => ({
    deleteFarmer
  }));

  const callVerificationDialog = async () => {
   if (props.farm.plantingDetails.length === 0) {
      //set dialog properties
      props.setAllowDelete(true);
      props.setActionHeader(`Bevestig: ${props.farm.businessName}`);
      props.setActionDetail("Is jy seker dat jy hierdie item wil verwyder?");
      props.setOpenConfirmationMessage(true);
      props.setConfirmationButtonLabels(["Ja", "Nee"]);
      props.setFarmToDelete(props.farm)
    } else {
      props.setAllowDelete(false);
      props.setActionHeader(`Inkorrek: ${props.farm.businessName}`);
      props.setActionDetail("Daar is bestellings wat hierdie klient gebruik");
      props.setOpenConfirmationMessage(true);
      props.setConfirmationButtonLabels(["Ok"]);
      props.farmToDelete(props.farm)
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
      }}
    >
      <div
        className="row pastOrderCard"
        style={{
          marginLeft: "1%",
          height: "100%",
          borderLeft: "2px solid #00a352",
        }}
      >
        <div className={`col-lg-4 pt-3 pb-3 `}>
          <h6 style={{ marginLeft: "1%" }}>Boerdery: {props?.businessName}</h6>
          <h6 style={{ marginLeft: "1%" }}>Plaasnaam: {props?.farmName}</h6>
          <h6 style={{ marginLeft: "1%" }}>Naaste Dorp: {props?.nearestTown}</h6>
        </div>

        <div className={`col-lg-4 pt-3 pb-3 `}>
          <h6 style={{ marginLeft: "1%" }}>Kontakpersoon: {props?.contactPerson}</h6>
          <h6 style={{ marginLeft: "1%" }}>Epos: {props?.email}</h6>
          <h6 style={{ marginLeft: "1%" }}>Kontaknommer: {props?.contactNumber}</h6>
        </div>

        <div className={`col-lg-4`}>
          <div className="row">
            <div className={`col-lg-4`}>
              <Button
                icon="pi pi-user-edit"
                label="WYSIG"
                iconPos="top"
                className="mt-2"
                style={{
                  color: GreenColor,
                  borderColor: "rgba(0,0,0,0)",
                  marginRight: "1%",
                  outline: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  props.setOpenConfirmationMessage(false);
                  props.setFarm(props.farm);
                  props.setBackPath("growerListView");
                  props.setPath("farmerReg");
                }}
              />
            </div>
            <div className={`col-lg-4`}>
              <Button
                icon="pi pi-trash"
                label="VERWYDER"
                iconPos="top"
                className="mt-2"
                style={{
                  color: GreenColor,
                  borderColor: "rgba(0,0,0,0)",
                  marginRight: "1%",
                  outline: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  callVerificationDialog();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GrowerCard;
