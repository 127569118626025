import { Button } from "primereact/button";
import LoginImageLeft from "../../../Images/PasMak.webp";
import FooterBanner from "../../../Images/FooterBanner.png";
import "./CropSelectionView.css";

const CropSelectionView = (props) => {
  const selectCrop = (cropId) => {
    props.setCropId(cropId);
    window.localStorage.setItem(props.agent.email, cropId);
    props.setPath("pastOrders");
  };

  const divStyle = {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-end",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <div style={{ width: "50%", height: "100%" }}>
          <img src={LoginImageLeft} className="imgStyle" alt="" />
        </div>
        <div style={divStyle}>
          <h1>Kies 'n gewas</h1>
          <div style={{ width: "40%" }}>
            <Button
              onClick={(e) => selectCrop(1)}
              style={{
                width: "100%",
                backgroundColor: "#00a352",
                borderColor: "#00a352",
              }}
              value={1}
              label="AARTAPPELS"
            />
            <Button
              className="mt-4"
              style={{
                width: "100%",
                backgroundColor: "#00a352",
                borderColor: "#00a352",
              }}
              onClick={() => selectCrop(2)}
              value={2}
              label="MAKADAMIAS"
            />
          </div>
        </div>
      </div>
      <img src={FooterBanner} style={{ width: "80.5%", marginTop: "-0.5%" }} alt="" />
      <div style={{ width: "80.5%", backgroundColor: "#745731", height: "5vh" }}></div>
    </div>
  );
};

export default CropSelectionView;
