import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "./CreatePlanView.css";
import { Card } from "primereact/card";
import Header from "../../../../Header";
import ProgramDuplication from "../../../Past Orders/ProgramDuplication";
import { locale, addLocale } from "primereact/api";
const CreatePlanView = (props) => {
  const [token,] = useState(localStorage.getItem("agentToken"));
  const [, setFarmers] = useState([]);
  const [farmerOptions, setFarmerOptions] = useState([]);
  const [plantingDetail, setPlantingDetail] = useState(null);
  const childRef = useRef(null);
  const [planName, setPlanName] = useState("");
  const [plantingDate, setPlantingDate] = useState("");
  const [plantingDateInt, setPlantingDateInt] = useState(null);

  addLocale("af", {
    firstDayOfWeek: 0,
    dayNames: [
      "Sondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrydag",
      "Saterdag",
    ],
    dayNamesShort: ["Son", "Maan", "Din", "Woen", "Don", "Vry", "Sat"],
    dayNamesMin: ["S", "M", "D", "W", "D", "V", "S"],
    monthNames: [
      "Januarie",
      "Februarie",
      "Maart",
      "April",
      "Mei",
      "Junie",
      "Julie",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    dateFormat: "dd/mm/yyy",
    today: "Vandag",
    clear: "Vee uit",
  });
  locale("af");

  const handleClick = async () => {
    childRef.current.childFunction1();
  };

  useEffect(() => {
    if (plantingDetail !== null) {
      CreateNewPlan();
    }
  }, [plantingDetail]);

  const getFarmers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/get_farmers", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      var test = [...data];
      setFarmers(test);
      data.map((item) => {
        farmerOptions.push(item.businessName);
      });
      setFarmerOptions([...farmerOptions]);
    }
  };

  const CreateNewPlan = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        planName: planName,
        planting_id: plantingDetail.id,
        plantingDate: plantingDateInt,
        archived: false,
        orderStatus_id: 1,
        crop_id: props.cropId?props.cropId:1
      }),
    };

    const response = await fetch("/api/create_plan", requestOptions);
    const data = await response.json();
    
    if (!response.ok) {
      //console.log('There is an error creating the plan')
    } else {
      props.setPlanDetails(data);
      props.setPath("planView");
    }
  };
 
  useEffect(() => {
    getFarmers();
  }, []);
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: "2%",
    alignItems: "center",
    justifyContent: "center",
  };
  const ContainerCardStyle = {
    display: "flex",
    flexDirection: "Column",
    width: "100%",
  };
  return (
    <div>
      <Header
        title={"PLAN BESONDERHEDE"}
        backPath={"pastOrders"}
        setPath={props.setPath}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Card className="cardName">
          <div style={ContainerCardStyle}>
            <div className="CardStyle">
              <div style={containerStyle}>
                {/* <h4>Program naam</h4> */}

                <span style={{ width: "100%", marginBottom: "2%" }}>
                  <label style={{ marginBottom: "2%" }}>Program Naam*</label>
                  <span className="p-float-label" style={{ width: "100%" }}>
                    <InputText
                      style={{ width: "100%" }}
                      value={planName}
                      onChange={(e) => setPlanName(e.target.value)}
                    />
                  </span>
                </span>
              </div>

              <div style={containerStyle}>
                <ProgramDuplication
                  ref={childRef}
                  setPlantingDetail={setPlantingDetail}
                  crops={props.crops}
                  cropId={props.cropId}
                />
              </div>

              <>
                <div style={containerStyle}>
                  <span
                    style={{
                      width: "100%",
                      marginBottom: "2%",
                      marginTop: "2%",
                    }}
                  >
                    <label style={{ marginBottom: "2%" }}>Plant datum*</label>
                    <span className="p-float-label" style={{ width: "100%" }}>
                      <Calendar
                        id="icon"
                        value={plantingDate}
                        dateFormat="dd/mm/yy"
                        locale="af"
                        style={{ width: "100%"}}
                        onChange={(e) => {
                          setPlantingDateInt(e.value.valueOf());
                          setPlantingDate(e.value.toLocaleDateString());
                        }}
                        showButtonBar
                        showIcon
                        
                      ></Calendar>
                    </span>
                  </span>
                </div>
              </>
            </div>
            {plantingDate !== "" && planName !== "" ? (
              <>
                <Button
                  label="Skep Nuwe Plan"
                  style={{ backgroundColor: "#00a352", borderColor: "#00a352" }}
                  onClick={handleClick}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CreatePlanView;
