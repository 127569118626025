import PastOrderCard from "./PastOrderCard";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import WhiteLogo from "../../../Images/SyngentaLogoWhite.png";
import MyPackLogo from "../../../Images/MyPackLogo.jpg";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PlanDetailsAPI from "../../../API/PlanDetailsAPI";
import AgentAPI from "../../../API/AgentAPI";
import MacadamiaImage from "../../../Images/macadamias.png";
import PotatoImage from "../../../Images/potatoes.png";
import MieliesImage from "../../../Images/mielies.png";
import "./pastOrders.css";
import "./crops.css";
import LoadingSpinner from "../LoadingSpinner";

const PastOrders = (props) => {
  const [, setToken] = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [enable] = useState(false);
  const [archived, toggleArchive] = useState(false);
  const [planIdFilter, setPlanIdFilter] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [showSpinner,setShowSpinner] = useState(false);
  
  props.setOpenConfirmationMessage(false) 
  
  useEffect(() => {
    let apiCall = null
    const timeout = new Promise((resolve) => setTimeout(resolve, 2000)); 
    setShowSpinner(true)
    if (props.userRoles.length === 0) {
      apiCall = AgentAPI.getRolesById(props?.agentDetail?.id ? props.agentDetail.id : -1).then((data) => {
        props.setAgentRoles([...data.map((i) => i.roleRel)]);
        if (data.map((i) => i.roleRel).find((i) => i.name === "AGENT")) {
          getAllPlans(props?.agentDetail?.id, props.cropId);
          if (props.filterPlan != null) setPlanIdFilter(props.filterPlan);
        }
      });
    }
    Promise.all([apiCall, timeout]).then(() => {
      setShowSpinner(false)
    });
  }, []);

  useEffect(() => {
    if (props.userRoles.find((i) => i === "AGENT")) {
      const spinning = showSpinner
      if(spinning && spinning === false)
        setShowSpinner(true)
      getAllPlans(props?.agentDetail?.id, props.cropId);
      if(spinning && spinning === false)
        setShowSpinner(false)
    }
  }, [archived]);

  useEffect(() => {
    if (planIdFilter !== null) {
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  }, [planIdFilter]);

  const updatePlanStatus = (planId, isSelected) => {
    let allPlansCopy = [...allPlans];
    var currentPlan = allPlansCopy.find((i) => i.id === planId);
    currentPlan.selected = isSelected;
    setAllPlans(allPlansCopy);
  };

  const getAllPlans = async (agent_id, cropId = props.cropId) => {
    if (props.planDetails && props.planDetails.constructor !== Array) {
      props.setPlanDetails(null);
    }
    setAllPlans([]);

    if (agent_id == null) {
      agent_id = props.agentDetail.id;
    }
    if (!agent_id) throw new Error("No Agent id in call to getAllPLans");
        
    let cachedCropId =  localStorage.getItem(props.agentDetail.email);
    if(cachedCropId && !isNaN(cachedCropId) && cropId !== parseInt(cachedCropId, 10))
      cropId = parseInt(cachedCropId, 10)
    let plans = await PlanDetailsAPI.getAllPlans(archived, props?.agentDetail?.id, cropId);
    if (props.filterPlan && props.filterPlan > 0 && plans) {
      plans = plans.filter((i) => i.id === props.filterPlan);
    }
    setAllPlans(plans);
  };

  const goToConsolidatedOrder = async () => {
    props.setPlanDetails(allPlans.filter((i) => i.selected));
    props.setPath("submitConsolidatedOrderView");
  };

  const GreenColor = "#00a352";

  const Logout = () => {
    props.setAgentRoles([]);
    props.setAgentDetail(null);
    setToken(null);
  };

  const handleCropChange = (e) => {
    if (props.crops.length === 0) return;
    props.setCropId(e);
    window.localStorage.setItem(props.agentDetail.email, e);
    const spinning = showSpinner
    if(spinning && spinning === false)
        setShowSpinner(true)
    getAllPlans(props?.agentDetail?.id, e);
    if(spinning && spinning === false)
      setShowSpinner(false)
  };

  const selectedCropTemplate = (option, props) => {
    if (option) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img alt={option.name} src={option.id === 1 ? PotatoImage : (option.id === 2 ? MacadamiaImage : MieliesImage)} style={{ width: "30px", height: "20px" }} />
            </div>
            <div className="col-8">
              <div>{option.name}</div>
            </div>
          </div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const cropOptionTemplate = (option) => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3">
            <img alt={option.name} src={option.id === 1 ? PotatoImage : (option.id === 2 ? MacadamiaImage: MieliesImage)} style={{ width: "30px", height: "20px" }} />
          </div>
          <div className="col-9">
            <div>{option.name}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        alignSelf: "center",
      }}
    >
      <Sidebar visible={visible} onHide={() => setVisible(false)}>
        <div style={{ display: "flex", flexDirection: "column", height: "50%" }}>
          <Button
            style={{
              color: GreenColor,
              borderColor: GreenColor,
              marginRight: "1%",
            }}
            className={`p-button-text ${readOnly ? "d-none" : ""} ${archived ? "d-none" : ""} ${
              props.userRoles.find((i) => i === "AGENT") ? "" : "d-none"
            } border-0`}
            icon="pi pi-plus-circle"
            label="KLIENTE"
            iconPos="left"
            onClick={() => {
              props.setPath("growerListView");
            }}
          />
          <Button
            icon="pi pi-folder"
            label="BESTELLINGS"
            iconPos="left"
            onClick={() => props.setPath("orderListView")}
            style={{
              color: GreenColor,
              height: "10vh",
              width: "100%",
              borderColor: "#FFFFFF",
            }}
            className="p-button-text"
          />

          <Button
            icon="pi pi-folder"
            label="PRYSE"
            iconPos="left"
            onClick={() => props.setPath("pricingDashboardView")}
            style={{
              color: GreenColor,
              height: "10vh",
              width: "100%",
              borderColor: "#FFFFFF",
            }}
            className={`p-button-text ${props.userRoles.find((i) => i === "CAMPAIGN LEAD") ? "" : "d-none"}`}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "50%",
            justifyContent: "flex-end",
          }}
        >
          <Button
            icon="pi pi-user"
            label="MY PROFIEL"
            iconPos="left"
            onClick={() => props.setPath("agentProfile")}
            style={{
              color: GreenColor,
              height: "10vh",
              width: "100%",
              borderColor: "#FFFFFF",
            }}
            className="p-button-text"
          />
          <Button
            icon="pi pi-sign-out"
            label="TEKEN UIT"
            iconPos="left"
            style={{
              color: GreenColor,
              height: "10vh",
              borderColor: "#FFFFFF",
              width: "100%",
            }}
            className="p-button-text"
            onClick={Logout}
          />
          <Button
            icon="pi pi-question-circle"
            label="HELP"
            iconPos="left"
            onClick={() => props.setPath("helpView")}
            style={{
              color: GreenColor,
              height: "10vh",
              borderColor: "#FFFFFF",
              width: "100%",
              marginBottom: "1%",
            }}
            className={`p-button-text`}
          />
          <img src={MyPackLogo} alt="" style={{ width: "100%" }} />
        </div>
      </Sidebar>

      <div
        style={{
          width: "100%",
          color: "#FFFFFF",
          backgroundColor: "#00a352",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <Button
          className={`ms-2 ${planIdFilter !== null ? "" : "d-none"}`}
          style={{
            marginLeft: "1%",
            fontSize: "20px",
            backgroundColor: "#00a352",
            borderColor: "#00a352",
          }}
          icon="pi pi-arrow-left"
          onClick={() => {
            props.setPath("submitConsolidatedOrderView");
          }}
        />

        <Button
          className={`${archived ? "d-none" : ""} ${planIdFilter !== null ? "d-none" : ""}`}
          style={{
            marginLeft: "5%",
            fontSize: "20px",
            backgroundColor: "#00a352",
            borderColor: "#00a352",
          }}
          icon="pi pi-align-justify"
          onClick={(e) => {
            setVisible(true);
          }}
        />
        <p className={`${archived ? "d-none" : ""} mb-0`} style={{ marginLeft: "2%", fontSize: "20px" }}>
          SPUITPROGRAMME
        </p>

        {/* Archiving */}
        <Button
          className={`ms-2 ${archived ? "" : "d-none"}`}
          style={{
            marginLeft: "1%",
            fontSize: "20px",
            backgroundColor: "#00a352",
            borderColor: "#00a352",
          }}
          icon="pi pi-arrow-left"
          onClick={() => {
            toggleArchive(!archived);
          }}
        />

        <label
          className={`ms-2 ${archived ? "" : "d-none"}`}
          style={{
            marginLeft: "1%",
            fontSize: "20px",
            backgroundColor: "#00a352",
            borderColor: "#00a352",
          }}
        >
          ARGIEWE
        </label>
        <div
          style={{
            width: "100%",
            color: "#FFFFFF",
            height: "30px",
            backgroundColor: "#00a352",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          <Dropdown
            style={{
              width: "200px",
              color: GreenColor,
              borderColor: GreenColor,
              marginRight: "4%",
            }}
            options={props.crops}
            optionLabel="name"
            optionValue="id"
            value={props.cropId}
            valueTemplate={selectedCropTemplate}
            itemTemplate={cropOptionTemplate}
            onChange={(e) => {
              handleCropChange(e.target.value);
            }}
            placeholder={"Crop"}
          />

          <img src={WhiteLogo} alt="" style={{ height: "100%", marginRight: "7%" }} />
        </div>
      </div>

      <div
        style={{
          margin: "1%",
          width: "90%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        {/* commented out - just in case customer wants it moved back here */}
        {/* <Dropdown
          style={{
            width: "auto",
            marginRight: "auto",
            marginLeft: "1%",
            color: GreenColor,
            borderColor: GreenColor,
          }}
          options={props.crops}
          optionLabel="name"
          optionValue="id"
          value={props.cropId}
          onChange={(e) => 
            {
              handleCropChange(e.target.value)
            }
            
          }
          placeholder={"Crop"}
        /> */}

        <Button
          style={{
            color: GreenColor,
            borderColor: GreenColor,
            marginRight: "1%",
            marginLeft: "1%",
          }}
          className={`p-button-text ${planIdFilter === null ? "" : "d-none"} ${archived ? "d-none" : ""} ${
            props.userRoles.find((i) => i === "AGENT") ? "" : "d-none"
          }`}
          icon="pi"
          label="KLIENTE"
          iconPos="left"
          onClick={() => {
            props.setPath("growerListView");
          }}
        />

        <Button
          style={{
            color: GreenColor,
            borderColor: GreenColor,
            marginRight: "1%",
          }}
          className={`p-button-text ${planIdFilter === null ? "" : "d-none"} ${archived ? "d-none" : ""} `}
          icon="pi"
          label="BESTELLINGS"
          iconPos="left"
          onClick={() => props.setPath("orderListView")}
        />

        <Button
          style={{
            color: GreenColor,
            borderColor: GreenColor,
            marginRight: "1%",
          }}
          className={`p-button-text ${archived ? "" : "d-none"} ${!readOnly ? "" : "d-none"}`}
          icon="pi pi-arrow-left"
          label="TERUG NA SPUITPROGRAMME"
          iconPos="left"
          onClick={() => toggleArchive(!archived)}
        />

        <Button
          style={{
            color: GreenColor,
            borderColor: GreenColor,
            marginRight: "1%",
          }}
          className={`p-button-text ${archived ? "d-none" : ""} ${!readOnly ? "" : "d-none"} ${props.userRoles.find((i) => i === "AGENT") ? "" : "d-none"}`}
          icon="pi"
          label="ARGIEF"
          iconPos="left"
          onClick={() => toggleArchive(!archived)}
        />

        <Button
          style={{
            color: GreenColor,
            borderColor: GreenColor,
            marginRight: "1%",
          }}
          disabled={enable}
          className={`p-button-text ${planIdFilter === null ? "" : "d-none"} ${archived ? "d-none" : ""} ${
            props.userRoles.find((i) => i === "AGENT") ? "" : "d-none"
          }`}
          icon="pi pi-plus"
          label="SKEP NUWE SPUITPROGRAM"
          iconPos="left"
          onClick={() => props.setPath("createPlanView")}
        />

        <Button
          style={{
            borderColor: "#6366F1",
            marginRight: "0%",
          }}
          disabled={!allPlans || allPlans.filter((i) => i.selected).length === 0}
          className={`p-button-text ${!allPlans || allPlans.filter((f) => f.orderStatus_id === 1).length === 0 ? "d-none" : ""} ${
            planIdFilter === null ? "" : "d-none"
          }
          ${archived ? "d-none" : ""}`}
          icon="pi pi-shopping-cart"
          label={`BESTEL SELEKSIE (${allPlans && allPlans.filter((i) => i.selected).length})`}
          iconPos="left"
          onClick={goToConsolidatedOrder}
        />
      </div>

      {showSpinner && 
      <LoadingSpinner loadingText={"Laai spuitprogramme ..."}/>
      }

      <TransitionGroup
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        {allPlans?allPlans
          .sort((a, b) => a.orderStatus_id - b.orderStatus_id || b.dateCreated - a.dateCreated)
          .map((item) => {
            return (
              <CSSTransition
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                }}
                key={item.id}
                timeout={1000}
                unmountOnExit
                classNames="my-node"
              >
                <PastOrderCard
                  planDetails={item}
                  setPlanDetails={props.setPlanDetails}
                  setPath={props.setPath}
                  getAllPlans={getAllPlans}
                  allPlans={allPlans}
                  setAllPlans={setAllPlans}
                  archived={archived}
                  readOnly={readOnly}
                  updatePlanStatus={updatePlanStatus}
                  setFarm={props.setFarm}
                  cropId={props.cropId}
                  crops={props.crops}
                  hideSpinner={true}
                />
              </CSSTransition>
            );
          })
        :<p>No plan found</p>}
      </TransitionGroup>
      
    </div>
  );
};
export default PastOrders;
